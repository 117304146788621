<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Marketplace Module
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Showcase, Sell, and Upsell <br> with Ease</h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Marketplace Module.png" />
            </div>
            <div class="guest-app-child-text">With Marketplace, guests can effortlessly browse and purchase your hotel’s
                services, curated packages, and local experiences—all through a seamless, mobile-friendly platform.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Marketplace Module.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Key Features of the Marketplace Module
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Monetize Your Hotel’s Offerings
                    </h3>
                    <div class="why-choose-text">List spa, dining, parking, curated packages, and add-ons like late
                        check-out and tours to maximize revenue.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Task Assignments.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Create Custom Packages
                    </h3>
                    <div class="why-choose-text">List unique packages—romantic getaways, family retreats, or business
                        stays—while promoting local tours, events, and attractions.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Real-Time Feedback.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Centralized Management with Real-Time Tracking
                    </h3>
                    <div class="why-choose-text">Manage bookings, payments, and services seamlessly while tracking
                        availability and updates in real-time.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            The Benefits of Using GoFriday’s Marketplace
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Drive Ancillary Sales</h3>
                <p>Turn every guest interaction into a revenue opportunity.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Simplify Operations</h3>
                <p>Automate inventory tracking, bookings, and payments.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Elevate Guest Satisfaction</h3>
                <p>Provide a seamless experience that encourages guests to explore and book more.</p>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <h2 class="guest-app-child-heading2" style="padding: 0% 5% 5% 16%;" *ngIf="!mobile">
                Why Choose Gofriday’s Marketplace Module?
            </h2>
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Marketplace.png">
                    </div>
                    <div fxLayout="column" fxFlex="44%" fxLayoutGap="2%">
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                All-in-One Platform
                            </div>
                            <div class="features-sub-text">
                                Showcase and sell everything your hotel offers, from room upgrades to local experiences.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Boost Ancillary Revenue
                            </div>
                            <div class="features-sub-text">
                                Upsell services, packages, and local activities seamlessly.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Seamless Guest Experience
                            </div>
                            <div class="features-sub-text">
                                Offer an intuitive, user-friendly booking system that enhances the guest journey.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 4% 5% 3% 5%;">
                        <h2 class="features-heading">
                            Why Choose Gofriday’s Marketplace Module?
                        </h2>
                    </div>
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/Marketplace.png">
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 15% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            All-in-One Platform
                        </div>
                        <div class="features-sub-text">
                            Showcase and sell everything your hotel offers, from room upgrades to local experiences.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Boost Ancillary Revenue
                        </div>
                        <div class="features-sub-text">
                            Upsell services, packages, and local activities seamlessly.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Seamless Guest Experience
                        </div>
                        <div class="features-sub-text">
                            Offer an intuitive, user-friendly booking system that enhances the guest journey.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="45%">
            <img class="bottom-image" src="assets/images/GoFriday Marketplace.png">
        </div>
        <div fxFlex="45%" fxLayout="column" fxLayoutGap="1%">
            <div>
                <h2 class="header">Get Started with GoFriday Marketplace Today</h2>
            </div>
            <div class="info-text">
                With GoFriday’s Marketplace module, you can showcase, upsell, and sell all your services in one place.
                Contact us for a Demo and see how Marketplace can help grow your revenue and enhance guest satisfaction!
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Get Started with GoFriday Marketplace Today</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/GoFriday Marketplace.png">
            </div>
            <div class="info-text">
                With GoFriday’s Marketplace module, you can showcase, upsell, and sell all your services in one place.
                Contact us for a Demo and see how Marketplace can help grow your revenue and enhance guest satisfaction!
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>