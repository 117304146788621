<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Task Management Software
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Task Management system: Streamline Operations, Elevate Performance
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Task Management Software.png" />
            </div>
            <div class="guest-app-child-text">GoFriday’s CLIO - Task Management software ensures seamless hotel
                operations with streamlined workflows and real-time tracking. From task assignments to SLA compliance,
                it boosts accountability, empowers teams, and brings precision to daily operations, ensuring nothing is
                overlooked.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Task Management Software.png" />
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h2 class="guest-app-child-heading2" style="padding-bottom: 7%;">
                Core Features of CLIO - Task Management software
            </h2>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Intelligent Task Management.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Intelligent Task Management
                    </h3>
                    <div class="why-choose-text">Automatically assign tasks based on priority, availability, or
                        location, and track progress in real time to ensure efficient resource use and timely
                        completion.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/SLA and SOP Compliance.png" alt="" />
                    <h3 class="why-choose-content-head">
                        SLA and SOP Compliance
                    </h3>
                    <div class="why-choose-text">Set Service Level Agreements (SLAs) and Standard Operating Procedures
                        (SOPs) for tasks to maintain consistency and quality across operations.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Automated Notifications.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Automated Notifications
                    </h3>
                    <div class="why-choose-text">Staff receives instant alerts for new assignments or changes,
                        minimizing delays and ensuring efficient communication.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multi-Department Coordination.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Multi-Department Coordination
                    </h3>
                    <div class="why-choose-text">Enable seamless collaboration between departments, such as
                        housekeeping, front desk, and engineering, for a unified operational flow.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Performance Reporting.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Performance Reporting
                    </h3>
                    <div class="why-choose-text">Generate detailed reports on task completion times, staff efficiency,
                        and SLA adherence to identify areas for improvement.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Integrated Feedback Resolution.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Integrated Feedback Resolution
                    </h3>
                    <div class="why-choose-text">Automatically convert guest feedback from CLIO - Feedback Management
                        into actionable tasks for faster issue resolution.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Operational Benefits for Your Hotel
        </h2>
        <div class="benefits-wrapper">
            <button (click)="bannerScrollLeft()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
            </button>
            <div #scrollableContainer class="benefits-container">
                <ng-container *ngFor="let benefit of benefits; let i = index">
                    <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                        <div class="benefit-number">{{ benefit.number }}</div>
                        <h3>{{ benefit.title }}</h3>
                        <p>{{ benefit.description }}</p>
                    </div>
                    <mat-divider *ngIf="i < benefits.length - 1 && !mobile"></mat-divider>
                </ng-container>
            </div>
            <button (click)="bannerScrollRight()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
            </button>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            How CLIO - Task Management software works
        </h3>
        <div class="benefits-wrapper1">
            <button (click)="bannerScrollLeft1()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
            </button>
            <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center"
                #scrollableContainerFeedbackManagement>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/actionable tasks.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Task Logging
                            </div>
                            <div class="knowledge-card-text">
                                Tasks are generated from guest requests, operational needs, or feedback inputs.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/AI-Driven Analysis.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Automatic Assignment
                            </div>
                            <div class="knowledge-card-text">
                                Tasks are assigned to the most suitable staff members based on predefined rules or
                                priorities.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/Performance Tracking.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Real-Time Updates
                            </div>
                            <div class="knowledge-card-text">
                                Teams track task progress via the CLIO Dashboard or mobile app, ensuring transparency
                                and accountability.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/Feedback Capture.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Completion and Review
                            </div>
                            <div class="knowledge-card-text">
                                Completed tasks are logged, reviewed for SLA compliance, and reported for operational
                                insights.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/Continuous Improvement.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Continuous Improvement
                            </div>
                            <div class="knowledge-card-text">
                                Data from task management is analyzed to identify patterns and implement process
                                improvements.
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <button (click)="bannerScrollRight1()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" class="info-container3 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%" fxLayout="column" fxLayoutGap="1%">
            <div>
                <h2 class="header">Integrated with the CLIO Operations Suite</h2>
            </div>
            <div class="info-text">
                CLIO - Task Management works seamlessly with other sub-products in the CLIO Suite, such as Feedback
                Management and Inventory Control, to create an interconnected ecosystem that drives operational
                excellence.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/Integrated with CLIO.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container3 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Integrated with the CLIO Operations Suite</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/Integrated with CLIO.png">
            </div>
            <div class="info-text">
                CLIO - Task Management works seamlessly with other sub-products in the CLIO Suite, such as Feedback
                Management and Inventory Control, to create an interconnected ecosystem that drives operational
                excellence.
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img class="bottom-image" src="assets/images/Elevate Operations with CLIO-Task Management.png">
        </div>
        <div fxFlex="45%" fxLayout="column" fxLayoutGap="1%">
            <div>
                <h2 class="header">Elevate Your Operations with 
                    <br> CLIO - Task Management</h2>
            </div>
            <div class="info-text">
                Make every task count. With CLIO - Task Management, you’ll ensure smooth operations, faster resolutions,
                and a superior guest experience—all while optimizing team performance.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Elevate Your Operations with <br> CLIO - Task Management</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Elevate Operations with CLIO-Task Management.png">
            </div>
            <div class="info-text">
                Make every task count. With CLIO - Task Management, you’ll ensure smooth operations, faster resolutions,
                and a superior guest experience—all while optimizing team performance.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>