import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FreeTrialComponent } from './pages/free-trial/free-trial.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { PlansComponent } from './pages/plans/plans.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { PartnerProgramComponent } from './pages/partner-program/partner-program.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ExperienceDetailComponent } from './pages/experience-detail/experience-detail.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { GuestAppComponent } from './pages/guest-app/guest-app.component';
import { ClioComponent } from './pages/clio/clio.component';
import { TaskAppComponent } from './pages/task-app/task-app.component';
import { CalendlyComponent } from './pages/calendly/calendly.component';
import { Blog1Component } from './pages/blog-page/blog1/blog1.component';
import { Blog2Component } from './pages/blog-page/blog2/blog2.component';
import { Blog3Component } from './pages/blog-page/blog3/blog3.component';
import { Blog4Component } from './pages/blog-page/blog4/blog4.component';
import { Blog5Component } from './pages/blog-page/blog5/blog5.component';
import { Blog6Component } from './pages/blog-page/blog6/blog6.component';
import { Blog7Component } from './pages/blog-page/blog7/blog7.component';
import { Blog8Component } from './pages/blog-page/blog8/blog8.component';
import { Blog9Component } from './pages/blog-page/blog9/blog9.component';
import { Blog10Component } from './pages/blog-page/blog10/blog10.component';
import { Blog11Component } from './pages/blog-page/blog11/blog11.component';
import { Blog12Component } from './pages/blog-page/blog12/blog12.component';
import { Blog13Component } from './pages/blog-page/blog13/blog13.component';
import { Blog14Component } from './pages/blog-page/blog14/blog14.component';
import { Blog15Component } from './pages/blog-page/blog15/blog15.component';
import { Blog16Component } from './pages/blog-page/blog16/blog16.component';
import { Blog17Component } from './pages/blog-page/blog17/blog17.component';
import { Blog18Component } from './pages/blog-page/blog18/blog18.component';
import { Products1Component } from './pages/features-page/products1/products1.component';
import { InRoomServicesComponent } from './pages/features-page/in-room-services/in-room-services.component';
import { FoodOrderingSystemComponent } from './pages/features-page/food-ordering-system/food-ordering-system.component';
import { VirtualConciergeServicesComponent } from './pages/features-page/virtual-concierge-services/virtual-concierge-services.component';
import { FeedbackSystemComponent } from './pages/features-page/feedback-system/feedback-system.component';
import { PropertyManagementSystemComponent } from './pages/features-page/property-management-system/property-management-system.component';
import { FeedbackManagementSoftwareComponent } from './pages/features-page/feedback-management-software/feedback-management-software.component';
import { TaskManagementSoftwareComponent } from './pages/features-page/task-management-software/task-management-software.component';
import { SpaManagementModuleComponent } from './pages/features-page/spa-management-module/spa-management-module.component';
import { FoodBeverageManagementComponent } from './pages/features-page/food-beverage-management/food-beverage-management.component';
import { EConferenceRoomManagementComponent } from './pages/features-page/e-conference-room-management/e-conference-room-management.component';
import { EConciergeManagementComponent } from './pages/features-page/e-concierge-management/e-concierge-management.component';
import { HousekeepingManagementComponent } from './pages/features-page/housekeeping-management/housekeeping-management.component';
import { CentralizedReservationManagementComponent } from './pages/features-page/centralized-reservation-management/centralized-reservation-management.component';
import { PerformanceManagementComponent } from './pages/features-page/performance-management/performance-management.component';
import { GuestRequestManagementComponent } from './pages/features-page/guest-request-management/guest-request-management.component';
import { EscalationsManagementComponent } from './pages/features-page/escalations-management/escalations-management.component';
import { CentralizedBusinessIntelligenceComponent } from './pages/features-page/centralized-business-intelligence/centralized-business-intelligence.component';
import { CentralizedGuestProfileManagementComponent } from './pages/features-page/centralized-guest-profile-management/centralized-guest-profile-management.component';
import { MarketplaceComponent } from './pages/features-page/marketplace/marketplace.component';
import { Blog19Component } from './pages/blog-page/blog19/blog19.component';
import { Blog20Component } from './pages/blog-page/blog20/blog20.component';
import { Blog21Component } from './pages/blog-page/blog21/blog21.component';
import { Blog22Component } from './pages/blog-page/blog22/blog22.component';
import { Blog23Component } from './pages/blog-page/blog23/blog23.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomePageComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/",
      hreflangs: [
        { lang: 'en-us', path: '/' },
        { lang: 'x-default', path: '/' },
        { lang: 'en', path: '/' }

      ]
    }
  },
  {
    path: 'free-trial',
    component: FreeTrialComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/free-trial",
      hreflangs: [
        { lang: 'en-us', path: '/free-trial' },
        { lang: 'x-default', path: '/free-trial' },
        { lang: 'en', path: '/free-trial' },
      ]
    }
  },
  {
    path: 'features',
    component: FeaturesPageComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'products/hotel-guest-experience-software',
    component: GuestAppComponent,
    data: {
      title: "Guest App: Hotel Guest Experience Software | GoFriday",
      description: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      ogTitle: "Guest App: Hotel Guest Experience Software | GoFriday",
      ogDescription: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      canonicalUrl: "/products/hotel-guest-experience-software",
      hreflangs: [
        { lang: 'en-us', path: '/products/hotel-guest-experience-software' },
        { lang: 'x-default', path: '/products/hotel-guest-experience-software' },
        { lang: 'en', path: '/products/hotel-guest-experience-software' }
      ]
    }
  },
  {
    path: 'products/online-check-in-to-online-check-out',
    component: Products1Component,
    data: {
      title: "Guest App: Seamless Online Check-In to Check-Out for Hotels | GoFriday",
      description: "Streamline your hotel’s check-in and check-out with GoFriday. Improve guest satisfaction and efficiency—Contact us Now!",
      ogTitle: "Guest App: Seamless Online Check-In to Check-Out for Hotels | GoFriday",
      ogDescription: "Streamline your hotel’s check-in and check-out with GoFriday. Improve guest satisfaction and efficiency—Contact us Now!",
      canonicalUrl: "/products/online-check-in-to-online-check-out",
      hreflangs: [
        { lang: 'en-us', path: '/products/online-check-in-to-online-check-out' },
        { lang: 'x-default', path: '/products/online-check-in-to-online-check-out' },
        { lang: 'en', path: '/products/online-check-in-to-online-check-out' }
      ]
    }
  },
  {
    path: 'products/in-room-services',
    component: InRoomServicesComponent,
    data: {
      title: "Guest App: Elevate Guest Experience with GoFriday In-Room Services software",
      description: "Streamline in-room requests and boost satisfaction with GoFriday’s In-Room Services software. Contact us now!",
      ogTitle: "Guest App: Elevate Guest Experience with GoFriday In-Room Services software",
      ogDescription: "Streamline in-room requests and boost satisfaction with GoFriday’s In-Room Services software. Contact us now!",
      canonicalUrl: "/products/in-room-services",
      hreflangs: [
        { lang: 'en-us', path: '/products/in-room-services' },
        { lang: 'x-default', path: '/products/in-room-services' },
        { lang: 'en', path: '/products/in-room-services' }
      ]
    }
  },
  {
    path: 'products/food-ordering-system',
    component: FoodOrderingSystemComponent,
    data: {
      title: "Guest App: Food Ordering System for Hotels | Seamless & Efficient",
      description: "Simplify food orders and improve guest satisfaction with GoFriday’s Food Ordering System. Streamlined, efficient, and easy to use. Contact us now!",
      ogTitle: "Guest App: Food Ordering System for Hotels | Seamless & Efficient",
      ogDescription: "Simplify food orders and improve guest satisfaction with GoFriday’s Food Ordering System. Streamlined, efficient, and easy to use. Contact us now!",
      canonicalUrl: "/products/food-ordering-system",
      hreflangs: [
        { lang: 'en-us', path: '/products/food-ordering-system' },
        { lang: 'x-default', path: '/products/food-ordering-system' },
        { lang: 'en', path: '/products/food-ordering-system' }
      ]
    }
  },
  {
    path: 'products/virtual-concierge-services',
    component: VirtualConciergeServicesComponent,
    data: {
      title: "Guest App: Food Ordering System for Hotels | Seamless & Efficient",
      description: "Elevate guest experiences with GoFriday’s Virtual Concierge. Simplify requests, personalize interactions, and increase guest satisfaction. Contact us now!",
      ogTitle: "Guest App: Food Ordering System for Hotels | Seamless & Efficient",
      ogDescription: "Elevate guest experiences with GoFriday’s Virtual Concierge. Simplify requests, personalize interactions, and increase guest satisfaction. Contact us now!",
      canonicalUrl: "/products/virtual-concierge-services",
      hreflangs: [
        { lang: 'en-us', path: '/products/virtual-concierge-services' },
        { lang: 'x-default', path: '/products/virtual-concierge-services' },
        { lang: 'en', path: '/products/virtual-concierge-services' }
      ]
    }
  },
  {
    path: 'products/360-feedback',
    component: FeedbackSystemComponent,
    data: {
      title: "Guest App: 360 Feedback System for Hotels | Guest & Staff Insights",
      description: "Collect valuable insights from guests and staff with GoFriday’s 360 Feedback system. Enhance service quality and satisfaction. Contact us now!",
      ogTitle: "Guest App: 360 Feedback System for Hotels | Guest & Staff Insights",
      ogDescription: "Collect valuable insights from guests and staff with GoFriday’s 360 Feedback system. Enhance service quality and satisfaction. Contact us now!",
      canonicalUrl: "/products/360-feedback",
      hreflangs: [
        { lang: 'en-us', path: '/products/360-feedback' },
        { lang: 'x-default', path: '/products/360-feedback' },
        { lang: 'en', path: '/products/360-feedback' }
      ]
    }
  },
  {
    path: 'products/property-management-system',
    component: PropertyManagementSystemComponent,
    data: {
      title: "Property Management System for Hotels | Streamline Operations",
      description: "Simplify hotel operations with GoFriday’s Property Management System. Manage bookings, tasks, and guest interactions in one platform. Contact us now!",
      ogTitle: "Property Management System for Hotels | Streamline Operations",
      ogDescription: "Simplify hotel operations with GoFriday’s Property Management System. Manage bookings, tasks, and guest interactions in one platform. Contact us now!",
      canonicalUrl: "/products/property-management-system",
      hreflangs: [
        { lang: 'en-us', path: '/products/property-management-system' },
        { lang: 'x-default', path: '/products/property-management-system' },
        { lang: 'en', path: '/products/property-management-system' }
      ]
    }
  },
  {
    path: 'products/feedback-management-software',
    component: FeedbackManagementSoftwareComponent,
    data: {
      title: "Feedback Management Software for Hotels | Improve Guest Experience",
      description: "Collect, manage, and analyze guest feedback with GoFriday’s Feedback Management Software. Enhance service quality and satisfaction. Contact us now!",
      ogTitle: "Feedback Management Software for Hotels | Improve Guest Experience",
      ogDescription: "Collect, manage, and analyze guest feedback with GoFriday’s Feedback Management Software. Enhance service quality and satisfaction. Contact us now!",
      canonicalUrl: "/products/feedback-management-software",
      hreflangs: [
        { lang: 'en-us', path: '/products/feedback-management-software' },
        { lang: 'x-default', path: '/products/feedback-management-software' },
        { lang: 'en', path: '/products/feedback-management-software' }
      ]
    }
  },
  {
    path: 'products/task-management-software',
    component: TaskManagementSoftwareComponent,
    data: {
      title: " Task Management Software for Hotels | Efficient Operations",
      description: "Optimize hotel operations with GoFriday’s Task Management Software. Effortlessly assign, track, and manage tasks to enhance efficiency. Contact us now!",
      ogTitle: " Task Management Software for Hotels | Efficient Operations",
      ogDescription: "Optimize hotel operations with GoFriday’s Task Management Software. Effortlessly assign, track, and manage tasks to enhance efficiency. Contact us now!",
      canonicalUrl: "/products/task-management-software",
      hreflangs: [
        { lang: 'en-us', path: '/products/task-management-software' },
        { lang: 'x-default', path: '/products/task-management-software' },
        { lang: 'en', path: '/products/task-management-software' }
      ]
    }
  },
  {
    path: 'products/spa-management-module',
    component: SpaManagementModuleComponent,
    data: {
      title: "Spa Management software for Hotels | Streamline Spa Services",
      description: "Simplify spa bookings and operations with GoFriday’s Spa Management software. Boost guest satisfaction and manage services effortlessly. Contact us now!",
      ogTitle: "Spa Management software for Hotels | Streamline Spa Services",
      ogDescription: "Simplify spa bookings and operations with GoFriday’s Spa Management software. Boost guest satisfaction and manage services effortlessly. Contact us now!",
      canonicalUrl: "/products/spa-management-module",
      hreflangs: [
        { lang: 'en-us', path: '/products/spa-management-module' },
        { lang: 'x-default', path: '/products/spa-management-module' },
        { lang: 'en', path: '/products/spa-management-module' }
      ]
    }
  },
  {
    path: 'products/food-beverage-management',
    component: FoodBeverageManagementComponent,
    data: {
      title: "Food & Beverage Management System for Hotels | Simplify Operations",
      description: "Streamline food and beverage operations with GoFriday’s F&B Management System. Enhance efficiency and guest satisfaction. Contact us now!",
      ogTitle: "Food & Beverage Management System for Hotels | Simplify Operations",
      ogDescription: "Streamline food and beverage operations with GoFriday’s F&B Management System. Enhance efficiency and guest satisfaction. Contact us now!",
      canonicalUrl: "/products/food-beverage-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/food-beverage-management' },
        { lang: 'x-default', path: '/products/food-beverage-management' },
        { lang: 'en', path: '/products/food-beverage-management' }
      ]
    }
  },
  {
    path: 'products/e-conference-room-management',
    component: EConferenceRoomManagementComponent,
    data: {
      title: "E-Conference Room Management | Simplify Event Coordination",
      description: "Manage bookings and streamline event planning with GoFriday’s E-Conference Room Management system. Enhance efficiency and guest experience. Contact us now!",
      ogTitle: "E-Conference Room Management | Simplify Event Coordination",
      ogDescription: "Manage bookings and streamline event planning with GoFriday’s E-Conference Room Management system. Enhance efficiency and guest experience. Contact us now!",
      canonicalUrl: "/products/e-conference-room-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/e-conference-room-management' },
        { lang: 'x-default', path: '/products/e-conference-room-management' },
        { lang: 'en', path: '/products/e-conference-room-management' }
      ]
    }
  },
  {
    path: 'products/e-concierge-management',
    component: EConciergeManagementComponent,
    data: {
      title: "Virtual Concierge Management System for Hotels | Enhance Guest Services",
      description: "Streamline guest requests and personalize services with GoFriday’s Virtual Concierge Management system. Boost guest satisfaction effortlessly. Contact us now!",
      ogTitle: "Virtual Concierge Management System for Hotels | Enhance Guest Services",
      ogDescription: "Streamline guest requests and personalize services with GoFriday’s Virtual Concierge Management system. Boost guest satisfaction effortlessly. Contact us now!",
      canonicalUrl: "/products/e-concierge-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/e-concierge-management' },
        { lang: 'x-default', path: '/products/e-concierge-management' },
        { lang: 'en', path: '/products/e-concierge-management' }
      ]
    }
  },
  {
    path: 'products/housekeeping-management',
    component: HousekeepingManagementComponent,
    data: {
      title: "Housekeeping Management System for Hotels | Streamline Hotel Operations",
      description: "Simplify housekeeping tasks and ensure spotless service with GoFriday’s Housekeeping Management system. Boost efficiency and guest satisfaction. Contact us now!",
      ogTitle: "Housekeeping Management System for Hotels | Streamline Hotel Operations",
      ogDescription: "Simplify housekeeping tasks and ensure spotless service with GoFriday’s Housekeeping Management system. Boost efficiency and guest satisfaction. Contact us now!",
      canonicalUrl: "/products/housekeeping-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/housekeeping-management' },
        { lang: 'x-default', path: '/products/housekeeping-management' },
        { lang: 'en', path: '/products/housekeeping-management' }
      ]
    }
  },
  {
    path: 'products/centralized-reservation-management',
    component: CentralizedReservationManagementComponent,
    data: {
      title: "Centralized Reservation System | Simplify Bookings",
      description: "Manage reservations effortlessly across channels with GoFriday’s Centralized Reservation software. Improve efficiency and revenue. Contact us now!",
      ogTitle: "Centralized Reservation System | Simplify Bookings",
      ogDescription: "Manage reservations effortlessly across channels with GoFriday’s Centralized Reservation software. Improve efficiency and revenue. Contact us now!",
      canonicalUrl: "/products/centralized-reservation-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/centralized-reservation-management' },
        { lang: 'x-default', path: '/products/centralized-reservation-management' },
        { lang: 'en', path: '/products/centralized-reservation-management' }
      ]
    }
  },
  {
    path: 'products/performance-management',
    component: PerformanceManagementComponent,
    data: {
      title: "Hotel Performance Management System | Optimize Staff Efficiency",
      description: "Maximize staff productivity and operational efficiency with GoFriday's Performance Management system. Track, analyze, and improve performance seamlessly. Contact us now!",
      ogTitle: "Hotel Performance Management System | Optimize Staff Efficiency",
      ogDescription: "Maximize staff productivity and operational efficiency with GoFriday's Performance Management system. Track, analyze, and improve performance seamlessly. Contact us now!",
      canonicalUrl: "/products/performance-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/performance-management' },
        { lang: 'x-default', path: '/products/performance-management' },
        { lang: 'en', path: '/products/performance-management' }
      ]
    }
  },
  {
    path: 'products/guest-request-management',
    component: GuestRequestManagementComponent,
    data: {
      title: "Guest Request Management System for Hotels | Simplify Operations",
      description: "Enhance guest satisfaction with GoFriday's Guest Request Management system. Streamline operations and ensure timely responses to all guest needs. Contact us now!",
      ogTitle: "Guest Request Management System for Hotels | Simplify Operations",
      ogDescription: "Enhance guest satisfaction with GoFriday's Guest Request Management system. Streamline operations and ensure timely responses to all guest needs. Contact us now!",
      canonicalUrl: "/products/guest-request-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/guest-request-management' },
        { lang: 'x-default', path: '/products/guest-request-management' },
        { lang: 'en', path: '/products/guest-request-management' }
      ]
    }
  },
  {
    path: 'products/escalations-management',
    component: EscalationsManagementComponent,
    data: {
      title: "Escalations Management System for Hotels | Ensure Quick Resolutions",
      description: "Handle guest issues effectively with GoFriday’s Escalations Management system. Streamline processes and resolve concerns promptly to boost guest satisfaction. Contact us now!",
      ogTitle: "Escalations Management System for Hotels | Ensure Quick Resolutions",
      ogDescription: "Handle guest issues effectively with GoFriday’s Escalations Management system. Streamline processes and resolve concerns promptly to boost guest satisfaction. Contact us now!",
      canonicalUrl: "/products/escalations-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/escalations-management' },
        { lang: 'x-default', path: '/products/escalations-management' },
        { lang: 'en', path: '/products/escalations-management' }
      ]
    }
  },
  {
    path: 'products/centralized-business-intelligence',
    component: CentralizedBusinessIntelligenceComponent,
    data: {
      title: "Centralized Business Intelligence for Hotels | Data-Driven Insights",
      description: "Unlock actionable insights across multi-property operations with GoFriday’s Centralized Business Intelligence. Make informed decisions to optimize performance and drive growth. Contact us now!",
      ogTitle: "Centralized Business Intelligence for Hotels | Data-Driven Insights",
      ogDescription: "Unlock actionable insights across multi-property operations with GoFriday’s Centralized Business Intelligence. Make informed decisions to optimize performance and drive growth. Contact us now!",
      canonicalUrl: "/products/centralized-business-intelligence",
      hreflangs: [
        { lang: 'en-us', path: '/products/centralized-business-intelligence' },
        { lang: 'x-default', path: '/products/centralized-business-intelligence' },
        { lang: 'en', path: '/products/centralized-business-intelligence' }
      ]
    }
  },
  {
    path: 'products/centralized-guest-profile-management',
    component: CentralizedGuestProfileManagementComponent,
    data: {
      title: "Centralized Guest Profile Management for Multi-Property Hotels",
      description: "Streamline guest data across multiple properties with GoFriday’s Centralized Guest Profile Management. Track preferences, personalize services, and enhance guest experiences seamlessly. Contact us now!",
      ogTitle: "Centralized Guest Profile Management for Multi-Property Hotels",
      ogDescription: "Streamline guest data across multiple properties with GoFriday’s Centralized Guest Profile Management. Track preferences, personalize services, and enhance guest experiences seamlessly. Contact us now!",
      canonicalUrl: "/products/centralized-guest-profile-management",
      hreflangs: [
        { lang: 'en-us', path: '/products/centralized-guest-profile-management' },
        { lang: 'x-default', path: '/products/centralized-guest-profile-management' },
        { lang: 'en', path: '/products/centralized-guest-profile-management' }
      ]
    }
  },
  {
    path: 'products/marketplace',
    component: MarketplaceComponent,
    data: {
      title: "Hotel Marketplace Platform | Expand Guest Offerings with GoFriday",
      description: "Enhance guest experiences with GoFriday’s Marketplace. Showcase and sell ancillary services, boost revenue, and provide seamless access to curated offerings. Explore now!",
      ogTitle: "Hotel Marketplace Platform | Expand Guest Offerings with GoFriday",
      ogDescription: "Enhance guest experiences with GoFriday’s Marketplace. Showcase and sell ancillary services, boost revenue, and provide seamless access to curated offerings. Explore now!",
      canonicalUrl: "/products/marketplace",
      hreflangs: [
        { lang: 'en-us', path: '/products/marketplace' },
        { lang: 'x-default', path: '/products/marketplace' },
        { lang: 'en', path: '/products/marketplace' }
      ]
    }
  },
  {
    path: 'products/clio-hotel-business-intelligence-software',
    component: ClioComponent,
    data: {
      title: "Clio: Best Hotel Business Intelligence Software | GoFriday",
      description: "Optimize operations and boost revenue with hotel business intelligence software. Gain insights, track performance, and make data-driven decisions effortlessly.",
      ogTitle: "Clio: Best Hotel Business Intelligence Software | GoFriday",
      ogDescription: "Optimize operations and boost revenue with hotel business intelligence software. Gain insights, track performance, and make data-driven decisions effortlessly.",
      canonicalUrl: "/products/clio-hotel-business-intelligence-software",
      hreflangs: [
        { lang: 'en-us', path: '/products/clio-hotel-business-intelligence-software' },
        { lang: 'x-default', path: '/products/clio-hotel-business-intelligence-software' },
        { lang: 'en', path: '/products/clio-hotel-business-intelligence-software' }
      ]
    }
  },
  {
    path: 'products/hotel-task-management-software',
    component: TaskAppComponent,
    data: {
      title: "Task App: Hotel Task Management Software | GoFriday",
      description: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      ogTitle: "Task App: Hotel Task Management Software | GoFriday",
      ogDescription: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      canonicalUrl: "/products/hotel-task-management-software",
      hreflangs: [
        { lang: 'en-us', path: '/products/hotel-task-management-software' },
        { lang: 'x-default', path: '/products/hotel-task-management-software' },
        { lang: 'en', path: '/products/hotel-task-management-software' }
      ]
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: "About us - Leading Hotel Management Software Solutions",
      description: "Learn how GoFriday empowers hotels to enhance guest experience and streamline operations with innovative AI-driven hospitality management software. Discover our mission, vision, and commitment to transforming the hotel industry.",
      ogTitle: "About us - Leading Hotel Management Software Solutions",
      ogDescription: "Learn how GoFriday empowers hotels to enhance guest experience and streamline operations with innovative AI-driven hospitality management software. Discover our mission, vision, and commitment to transforming the hotel industry.",
      canonicalUrl: "/about-us",
      hreflangs: [
        { lang: 'en-us', path: '/about-us' },
        { lang: 'x-default', path: '/about-us' },
        { lang: 'en', path: '/about-us' }
      ]
    }
  },
  {
    path: 'plans',
    component: PlansComponent,
    data: {
      title: "Flexible Hospitality Management Software Plans",
      description: "Explore GoFriday’s tailored plans to enhance hotel operations and guest experience. Whether you're a small boutique or a large chain, find the perfect hospitality management software to boost revenue and streamline processes.",
      ogTitle: "Flexible Hospitality Management Software Plans",
      ogDescription: "Explore GoFriday’s tailored plans to enhance hotel operations and guest experience. Whether you're a small boutique or a large chain, find the perfect hospitality management software to boost revenue and streamline processes.",
      canonicalUrl: "/plans",
      hreflangs: [
        { lang: 'en-us', path: '/plans' },
        { lang: 'x-default', path: '/plans' },
        { lang: 'en', path: '/plans' }
      ]
    }
  },
  {
    path: 'blog',
    component: BlogPageComponent,
    data: {
      title: "Blog - Hospitality Insights & Hotel Management Tips",
      description: "Stay updated with the latest trends in hotel management, guest experience, and hospitality technology. Explore expert tips and industry insights from GoFriday to help your hotel thrive.",
      ogTitle: "Blog - Hospitality Insights & Hotel Management Tips",
      ogDescription: "Stay updated with the latest trends in hotel management, guest experience, and hospitality technology. Explore expert tips and industry insights from GoFriday to help your hotel thrive.",
      canonicalUrl: "/blog",
      hreflangs: [
        { lang: 'en-us', path: '/blog' },
        { lang: 'x-default', path: '/blog' },
        { lang: 'en', path: '/blog' }
      ]
    }
  },
  {
    path: 'blog/hicsa',
    component: Blog1Component,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/blog/hicsa",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hicsa' },
        { lang: 'x-default', path: '/blog/hicsa' },
        { lang: 'en', path: '/blog/hicsa' }
      ],
    }
  },
  {
    path: 'blog/guest-centric-revolution',
    component: Blog2Component,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/blog/guest-centric-revolution",
      hreflangs: [
        { lang: 'en-us', path: '/blog/guest-centric-revolution' },
        { lang: 'x-default', path: '/blog/guest-centric-revolution' },
        { lang: 'en', path: '/blog/guest-centric-revolution' }
      ],
    }
  },
  {
    path: 'blog/what-is-a-hotel-crm',
    component: Blog3Component,
    data: {
      title: "What Is A Hotel CRM & How It Maximizes Hotel Success?",
      description: "Discover how hotel CRM systems can enhance guest experience, improve operations, and increase revenue in the hospitality industry. Read Now!",
      ogTitle: "What Is A Hotel CRM & How It Maximizes Hotel Success?",
      ogDescription: "Discover how hotel CRM systems can enhance guest experience, improve operations, and increase revenue in the hospitality industry. Read Now!",
      canonicalUrl: "/blog/what-is-a-hotel-crm",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-is-a-hotel-crm' },
        { lang: 'x-default', path: '/blog/what-is-a-hotel-crm' },
        { lang: 'en', path: '/blog/what-is-a-hotel-crm' }
      ]
    }
  },
  {
    path: 'blog/features-of-a-crm-system-for-hotels',
    component: Blog4Component,
    data: {
      title: "9 Core Features of a CRM System for Hotels",
      description: "Explore the essential features of a CRM system that drive better customer relationships, streamline operations, and boost business efficiency. Read Now!",
      ogTitle: "9 Core Features of a CRM System for Hotels",
      ogDescription: "Explore the essential features of a CRM system that drive better customer relationships, streamline operations, and boost business efficiency. Read Now!",
      canonicalUrl: "/blog/features-of-a-crm-system-for-hotels",
      hreflangs: [
        { lang: 'en-us', path: '/blog/features-of-a-crm-system-for-hotels' },
        { lang: 'x-default', path: '/blog/features-of-a-crm-system-for-hotels' },
        { lang: 'en', path: '/blog/features-of-a-crm-system-for-hotels' }
      ]
    }
  },
  {
    path: 'blog/automated-hotel',
    component: Blog5Component,
    data: {
      title: "Automated Hotel: Benefits, Implementation & Risks",
      description: "Discover the benefits, implementation strategies, and risks of hotel automation. Learn how to enhance guest experience & streamline operations effectively",
      ogTitle: "Automated Hotel: Benefits, Implementation & Risks",
      ogDescription: "Discover the benefits, implementation strategies, and risks of hotel automation. Learn how to enhance guest experience & streamline operations effectively",
      canonicalUrl: "/blog/automated-hotel",
      hreflangs: [
        { lang: 'en-us', path: '/blog/automated-hotel' },
        { lang: 'x-default', path: '/blog/automated-hotel' },
        { lang: 'en', path: '/blog/automated-hotel' }
      ]
    }
  },
  {
    path: 'blog/hotel-profit-margin',
    component: Blog6Component,
    data: {
      title: "What is a Hotel Profit Margin? Strategies to Boost in 2024",
      description: "Learn what hotel profit margins are and discover effective strategies to boost your hotel’s profitability in 2024. Maximize revenue & control costs efficiently",
      ogTitle: "What is a Hotel Profit Margin? Strategies to Boost in 2024",
      ogDescription: "Learn what hotel profit margins are and discover effective strategies to boost your hotel’s profitability in 2024. Maximize revenue & control costs efficiently",
      canonicalUrl: "/blog/hotel-profit-margin",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hotel-profit-margin' },
        { lang: 'x-default', path: '/blog/hotel-profit-margin' },
        { lang: 'en', path: '/blog/hotel-profit-margin' }
      ]
    }
  },
  {
    path: 'blog/what-is-hospitality-management',
    component: Blog7Component,
    data: {
      title: "What Is Hospitality Management: Careers, Skills, and More",
      description: "Explore what is hospitality management, from its definition and importance to career opportunities, essential skills, and industry trends. Read now!",
      ogTitle: "What Is Hospitality Management: Careers, Skills, and More",
      ogDescription: "Explore what is hospitality management, from its definition and importance to career opportunities, essential skills, and industry trends. Read now!",
      canonicalUrl: "/blog/what-is-hospitality-management",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-is-hospitality-management' },
        { lang: 'x-default', path: '/blog/what-is-hospitality-management' },
        { lang: 'en', path: '/blog/what-is-hospitality-management' }
      ]
    }
  },
  {
    path: 'blog/how-to-improve-hotel-revenue',
    component: Blog8Component,
    data: {
      title: "13 Smart Strategies On How to Improve Hotel Revenue",
      description: "Discover actionable strategies on how to improve hotel revenue, from optimizing pricing and marketing to improving guest experience and leveraging technology.",
      ogTitle: "13 Smart Strategies On How to Improve Hotel Revenue",
      ogDescription: "Discover actionable strategies on how to improve hotel revenue, from optimizing pricing and marketing to improving guest experience and leveraging technology.",
      canonicalUrl: "/blog/how-to-improve-hotel-revenue",
      hreflangs: [
        { lang: 'en-us', path: '/blog/how-to-improve-hotel-revenue' },
        { lang: 'x-default', path: '/blog/how-to-improve-hotel-revenue' },
        { lang: 'en', path: '/blog/how-to-improve-hotel-revenue' }
      ]
    }
  },
  {
    path: 'blog/what-is-a-hotel-cancellation-policy',
    component: Blog9Component,
    data: {
      title: "Hotel Cancellation Policy: Everything You Need to Know",
      description: "Understand hotel cancellation policies, key terms, and tips to avoid fees. Get insights on flexible bookings, refunds, and exceptions. Read now!",
      ogTitle: "Hotel Cancellation Policy: Everything You Need to Know",
      ogDescription: "Understand hotel cancellation policies, key terms, and tips to avoid fees. Get insights on flexible bookings, refunds, and exceptions. Read now!",
      canonicalUrl: "/blog/what-is-a-hotel-cancellation-policy",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-is-a-hotel-cancellation-policy' },
        { lang: 'x-default', path: '/blog/what-is-a-hotel-cancellation-policy' },
        { lang: 'en', path: '/blog/what-is-a-hotel-cancellation-policy' }
      ]
    }
  },
  {
    path: 'blog/benefits-of-crm-in-hotel-industry',
    component: Blog10Component,
    data: {
      title: "6 Benefits Of CRM In Hotel Industry",
      description: "Discover how CRM software boosts efficiency and guest satisfaction in the hotel industry. Learn about features, uses, and tips for choosing the right CRM.",
      ogTitle: "6 Benefits Of CRM In Hotel Industry",
      ogDescription: "Discover how CRM software boosts efficiency and guest satisfaction in the hotel industry. Learn about features, uses, and tips for choosing the right CRM.",
      canonicalUrl: "/blog/benefits-of-crm-in-hotel-industry",
      hreflangs: [
        { lang: 'en-us', path: '/blog/benefits-of-crm-in-hotel-industry' },
        { lang: 'x-default', path: '/blog/benefits-of-crm-in-hotel-industry' },
        { lang: 'en', path: '/blog/benefits-of-crm-in-hotel-industry' }
      ]
    }
  },
  {
    path: 'blog/how-to-travel-cheap',
    component: Blog11Component,
    data: {
      title: "How to Travel Cheaper: Insider Tips for Affordable Adventures",
      description: "Discover insider tips on how to travel cheaper and make the most of your adventures without breaking the bank. Start planning your affordable getaway today!",
      ogTitle: "How to Travel Cheaper: Insider Tips for Affordable Adventures",
      ogDescription: "Discover insider tips on how to travel cheaper and make the most of your adventures without breaking the bank. Start planning your affordable getaway today!",
      canonicalUrl: "/blog/how-to-travel-cheap",
      hreflangs: [
        { lang: 'en-us', path: '/blog/how-to-travel-cheap' },
        { lang: 'x-default', path: '/blog/how-to-travel-cheap' },
        { lang: 'en', path: '/blog/how-to-travel-cheap' }
      ]
    }
  },
  {
    path: 'blog/difference-between-hotel-and-motel',
    component: Blog12Component,
    data: {
      title: "What's The Difference Between Hotel And Motel?",
      description: "Discover the key differences between hotels and motels, from amenities and pricing to ideal uses for your travel needs. Find the perfect stay!",
      ogTitle: "What's The Difference Between Hotel And Motel?",
      ogDescription: "Discover the key differences between hotels and motels, from amenities and pricing to ideal uses for your travel needs. Find the perfect stay!",
      canonicalUrl: "/blog/difference-between-hotel-and-motel",
      hreflangs: [
        { lang: 'en-us', path: '/blog/difference-between-hotel-and-motel' },
        { lang: 'x-default', path: '/blog/difference-between-hotel-and-motel' },
        { lang: 'en', path: '/blog/difference-between-hotel-and-motel' }
      ]
    }
  },
  {
    path: 'blog/how-to-start-a-travel-agency',
    component: Blog13Component,
    data: {
      title: "Step-by-Step Guide: How to Start a Travel Agency",
      description: "Learn how to start a travel agency with our step-by-step guide, covering everything from planning and licensing to marketing and customer service",
      ogTitle: "Step-by-Step Guide: How to Start a Travel Agency",
      ogDescription: "Learn how to start a travel agency with our step-by-step guide, covering everything from planning and licensing to marketing and customer service",
      canonicalUrl: "/blog/how-to-start-a-travel-agency",
      hreflangs: [
        { lang: 'en-us', path: '/blog/how-to-start-a-travel-agency' },
        { lang: 'x-default', path: '/blog/how-to-start-a-travel-agency' },
        { lang: 'en', path: '/blog/how-to-start-a-travel-agency' }
      ]
    }
  },
  {
    path: 'blog/gofriday-best-crm-software-for-hotels',
    component: Blog14Component,
    data: {
      title: "Best Hotel CRM Software: Why Choose GoFriday?",
      description: "Discover the best CRM software for hotels to boost guest satisfaction, streamline bookings, and improve operations. Learn about key features and benefits today",
      ogTitle: "Best Hotel CRM Software: Why Choose GoFriday?",
      ogDescription: "Discover the best CRM software for hotels to boost guest satisfaction, streamline bookings, and improve operations. Learn about key features and benefits today",
      canonicalUrl: "/blog/gofriday-best-crm-software-for-hotels",
      hreflangs: [
        { lang: 'en-us', path: '/blog/gofriday-best-crm-software-for-hotels' },
        { lang: 'x-default', path: '/blog/gofriday-best-crm-software-for-hotels' },
        { lang: 'en', path: '/blog/gofriday-best-crm-software-for-hotels' }
      ]
    }
  },
  {
    path: 'blog/ai-in-hospitality-industry',
    component: Blog15Component,
    data: {
      title: "AI in Hospitality Industry: Transforming Guest Experiences",
      description: "Explore how AI is transforming the hospitality industry by enhancing guest experiences, personalizing services, and streamlining operations",
      ogTitle: "AI in Hospitality Industry: Transforming Guest Experiences",
      ogDescription: "Explore how AI is transforming the hospitality industry by enhancing guest experiences, personalizing services, and streamlining operations",
      canonicalUrl: "/blog/ai-in-hospitality-industry",
      hreflangs: [
        { lang: 'en-us', path: '/blog/ai-in-hospitality-industry' },
        { lang: 'x-default', path: '/blog/ai-in-hospitality-industry' },
        { lang: 'en', path: '/blog/ai-in-hospitality-industry' }
      ]
    }
  },
  {
    path: 'blog/what-does-travel-sustainable-mean',
    component: Blog16Component,
    data: {
      title: "What Does Travel Sustainable Mean? Key Concepts Explained",
      description: "Learn what sustainable travel means, key concepts like eco-friendly tourism, and how travelers and the industry can promote responsible, eco-conscious travel",
      ogTitle: "What Does Travel Sustainable Mean? Key Concepts Explained",
      ogDescription: "Learn what sustainable travel means, key concepts like eco-friendly tourism, and how travelers and the industry can promote responsible, eco-conscious travel",
      canonicalUrl: "/blog/what-does-travel-sustainable-mean",
      hreflangs: [
        { lang: 'en-us', path: '/blog/what-does-travel-sustainable-mean' },
        { lang: 'x-default', path: '/blog/what-does-travel-sustainable-mean' },
        { lang: 'en', path: '/blog/what-does-travel-sustainable-mean' }
      ]
    }
  },
  {
    path: 'blog/hotel-room-management-system',
    component: Blog17Component,
    data: {
      title: "Hotel Room Management System: A Guide to Efficiency",
      description: "Discover how a Hotel Room Management System boosts efficiency, enhances guest experience, and streamlines operations for better profitability and smoother management",
      ogTitle: "Hotel Room Management System: A Guide to Efficiency",
      ogDescription: "Discover how a Hotel Room Management System boosts efficiency, enhances guest experience, and streamlines operations for better profitability and smoother management",
      canonicalUrl: "/blog/hotel-room-management-system",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hotel-room-management-system' },
        { lang: 'x-default', path: '/blog/hotel-room-management-system' },
        { lang: 'en', path: '/blog/hotel-room-management-system' }
      ]
    }
  },
  {
    path: 'blog/cloud-based-crm-benefits-for-hotel-business',
    component: Blog18Component,
    data: {
      title: "Top Benefits for Hotel Business",
      description: "Discover how cloud-based CRM can transform your business. Explore benefits, features, and top providers in this comprehensive guide. Read now!",
      ogTitle: "Top Benefits for Hotel Business",
      ogDescription: "Discover how cloud-based CRM can transform your business. Explore benefits, features, and top providers in this comprehensive guide. Read now!",
      canonicalUrl: "/blog/cloud-based-crm-benefits-for-hotel-business",
      hreflangs: [
        { lang: 'en-us', path: '/blog/cloud-based-crm-benefits-for-hotel-business' },
        { lang: 'x-default', path: '/blog/cloud-based-crm-benefits-for-hotel-business' },
        { lang: 'en', path: '/blog/cloud-based-crm-benefits-for-hotel-business' }
      ]
    }
  },
  {
    path: 'blog/best-accounting-software-for-hotel',
    component: Blog19Component,
    data: {
      title: "Why Is Gofriday The Best Accounting Software for Hotels?",
      description: "Discover the best accounting software for hotels in 2025. Explore top features, benefits, and how to choose the right tool to streamline hotel finances.",
      ogTitle: "Why Is Gofriday The Best Accounting Software for Hotels?",
      ogDescription: "Discover the best accounting software for hotels in 2025. Explore top features, benefits, and how to choose the right tool to streamline hotel finances.",
      canonicalUrl: "/blog/best-accounting-software-for-hotel",
      hreflangs: [
        { lang: 'en-us', path: '/blog/best-accounting-software-for-hotel' },
        { lang: 'x-default', path: '/blog/best-accounting-software-for-hotel' },
        { lang: 'en', path: '/blog/best-accounting-software-for-hotel' }
      ]
    }
  },
  {
    path: 'blog/importance-of-customer-feedback-in-hospitality-industry',
    component: Blog20Component,
    data: {
      title: "Importance Of Customer Feedback In Hospitality Industry",
      description: "Discover the value of customer feedback in the hospitality industry. Learn how it drives improvements in service, guest experience, and business growth.",
      ogTitle: "Importance Of Customer Feedback In Hospitality Industry",
      ogDescription: "Discover the value of customer feedback in the hospitality industry. Learn how it drives improvements in service, guest experience, and business growth.",
      canonicalUrl: "/blog/importance-of-customer-feedback-in-hospitality-industry",
      hreflangs: [
        { lang: 'en-us', path: '/blog/importance-of-customer-feedback-in-hospitality-industry' },
        { lang: 'x-default', path: '/blog/importance-of-customer-feedback-in-hospitality-industry' },
        { lang: 'en', path: '/blog/importance-of-customer-feedback-in-hospitality-industry' }
      ]
    }
  },
  {
    path: 'blog/hotel-pos-software-for-hospitality-industry',
    component: Blog21Component,
    data: {
      title: "Hotel POS Software for Hospitality Industry | Streamline Operations & Boost Guest Satisfaction",
      description: "Discover how hotel POS software transforms hospitality operations. Learn about key features like real-time integration, inventory management, and mobile access to enhance guest experiences and streamline workflows.",
      ogTitle: "Hotel POS Software for Hospitality Industry | Streamline Operations & Boost Guest Satisfaction",
      ogDescription: "Discover how hotel POS software transforms hospitality operations. Learn about key features like real-time integration, inventory management, and mobile access to enhance guest experiences and streamline workflows.",
      canonicalUrl: "/blog/hotel-pos-software-for-hospitality-industry",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hotel-pos-software-for-hospitality-industry' },
        { lang: 'x-default', path: '/blog/hotel-pos-software-for-hospitality-industry' },
        { lang: 'en', path: '/blog/hotel-pos-software-for-hospitality-industry' }
      ]
    }
  },
  {
    path: 'blog/hotel-check-in-tips-and-mistakes-to-avoid',
    component: Blog22Component,
    data: {
      title: "Hotel Check-In Tips: How to Check Into a Hotel Smoothly & Avoid Common Mistakes",
      description: "Learn how to check into a hotel like a pro! Discover essential tips, step-by-step guidance, and common mistakes to avoid for a seamless check-in experience. Perfect for travelers and hospitality professionals.",
      ogTitle: "Hotel Check-In Tips: How to Check Into a Hotel Smoothly & Avoid Common Mistakes",
      ogDescription: "Learn how to check into a hotel like a pro! Discover essential tips, step-by-step guidance, and common mistakes to avoid for a seamless check-in experience. Perfect for travelers and hospitality professionals.",
      canonicalUrl: "/blog/hotel-check-in-tips-and-mistakes-to-avoid",
      hreflangs: [
        { lang: 'en-us', path: '/blog/hotel-check-in-tips-and-mistakes-to-avoid' },
        { lang: 'x-default', path: '/blog/hotel-check-in-tips-and-mistakes-to-avoid' },
        { lang: 'en', path: '/blog/hotel-check-in-tips-and-mistakes-to-avoid' }
      ]
    }
  },
  {
    path: 'blog/difference-between-2-star-and-3-star-hotel',
    component: Blog23Component,
    data: {
      title: "Difference Between 2-Star and 3-Star Hotels: Amenities, Services, and Pricing",
      description: "Explore the key differences between 2-star and 3-star hotels, from amenities and room quality to pricing and guest experience. Find the perfect stay for your needs!",
      ogTitle: "Difference Between 2-Star and 3-Star Hotels: Amenities, Services, and Pricing",
      ogDescription: "Explore the key differences between 2-star and 3-star hotels, from amenities and room quality to pricing and guest experience. Find the perfect stay for your needs!",
      canonicalUrl: "/blog/difference-between-2-star-and-3-star-hotel",
      hreflangs: [
        { lang: 'en-us', path: '/blog/difference-between-2-star-and-3-star-hotel' },
        { lang: 'x-default', path: '/blog/difference-between-2-star-and-3-star-hotel' },
        { lang: 'en', path: '/blog/difference-between-2-star-and-3-star-hotel' }
      ]
    }
  },
  {
    path: 'partner-program',
    component: PartnerProgramComponent,
    data: {
      title: "Partner Program - Join Us & Enhance Hospitality Solutions",
      description: "Explore the GoFriday Partner Program and discover opportunities to collaborate, expand your offerings, and drive growth. Partner with us to deliver cutting-edge hotel management solutions and enhance guest experiences.",
      ogTitle: "Partner Program - Join Us & Enhance Hospitality Solutions",
      ogDescription: "Explore the GoFriday Partner Program and discover opportunities to collaborate, expand your offerings, and drive growth. Partner with us to deliver cutting-edge hotel management solutions and enhance guest experiences.",
      canonicalUrl: "/partner-program",
      hreflangs: [
        { lang: 'en-us', path: '/partnerprogram' },
        { lang: 'x-default', path: '/partnerprogram' },
        { lang: 'en', path: '/partnerprogram' }
      ]
    }
  },
  {
    path: 'experiences',
    component: ExperienceComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/experiences",
      hreflangs: [
        { lang: 'en-us', path: '/experiences' },
        { lang: 'x-default', path: '/experiences' },
        { lang: 'en', path: '/experiences' }
      ]
    }
  },
  {
    path: 'experience-details',
    component: ExperienceDetailComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/experience-details",
      hreflangs: [
        { lang: 'en-us', path: '/experience-details' },
        { lang: 'x-default', path: '/experience-details' },
        { lang: 'en', path: '/experience-details' }
      ]
    }
  },
  {
    path: 'reserve',
    component: ReserveComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/reserve",
      hreflangs: [
        { lang: 'en-us', path: '/reserve' },
        { lang: 'x-default', path: '/reserve' },
        { lang: 'en', path: '/reserve' }
      ]
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GGoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/privacy-policy",
      hreflangs: [
        { lang: 'en-us', path: '/privacy-policy' },
        { lang: 'x-default', path: '/privacy-policy' },
        { lang: 'en', path: '/privacy-policy' }
      ]
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GGoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/terms-conditions",
      hreflangs: [
        { lang: 'en-us', path: '/terms-conditions' },
        { lang: 'x-default', path: '/terms-conditions' },
        { lang: 'en', path: '/terms-conditions' }
      ]
    }
  },
  {
    path: 'calendly-thank-you',
    component: CalendlyComponent,
    data: {
      title: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      description: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFriday Hotel Management Software: Rated #1 for efficiency",
      ogDescription: "GoFriday Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/terms-conditions",
      hreflangs: [
        { lang: 'en-us', path: '/calendly-thank-you' },
        { lang: 'x-default', path: '/calendly-thank-you' },
        { lang: 'en', path: '/calendly-thank-you' }
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }