import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { PopupComponent } from '../../popup/popup.component';
import { colorObj } from 'src/shared/color-object';
import { first } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog2',
  templateUrl: './blog2.component.html',
  styleUrls: ['./blog2.component.css']
})
export class Blog2Component {

  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  isSubscribePopup: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'How can AI benefit my hotel?',
      answer: 'AI can significantly benefit your hotel by enabling you to provide highly personalized experiences, streamline operations, enhance customer service, gain data-driven insights, and gain a competitive advantage in the market, allowing you to deliver the seamless experiences today guests expect and drive satisfaction and loyalty.',
      open: false
    },
    {
      question: 'Is AI expensive to implement in a hotel?',
      answer: 'Implementing AI in hotels can be a significant investment, but it doesnt have to be a luxury only the biggest chains can afford. For example, AI-powered chatbots and virtual assistants can be integrated into existing hotel systems, providing personalized support and enhancing the guest experience without requiring extensive hardware upgrades or significant staff training. Additionally, cloud-based AI services offer a more affordable entry point into AI, allowing smaller hotels to benefit from AI-driven insights and automation without breaking the bank.',
      open: false
    },
    {
      question: 'Will AI replace human staff in hotels?',
      answer: 'AI complements human staff by automating repetitive tasks, enabling staff to focus on delivering personalized service and enhancing guest experiences.',
      open: false
    }
  ];

  articles = [
    {
      title: 'What Is A Hotel CRM & How It Maximizes Hotel Success?',
      text: 'Discover how hotel CRM systems can enhance guest experience...',
      image: 'assets/images/CRM-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewHotelCRM()
    },
    {
      title: '5 core features of a crm system for hotels',
      text: 'Let’s consider a situation where you know your guests’ preferences so well, that every... ',
      image: 'assets/images/5-core-features-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewCoreFeaturesOfACrm()
    },
    {
      title: 'Hotel cancellation policy: everything you need to know',
      text: 'However simple it may sound, booking a hotel can be harder than one imagines...',
      image: 'assets/images/cancellation-policy-image.png',
      readTime: '7 Minutes Read',
      onClick: () => this.viewCancellationPolicy()
    },

  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    this.hotelService.hideContent
      .pipe(first()).subscribe(step => {
        if (step === true) {
          this.isSubscribePopup = true;
        }
        else {
          this.isSubscribePopup = false;
        }
      })
    if (!this.isPopupOpen && !this.isSubscribePopup) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'AI-In-Hospitality',
      'AIbenifits',
      'experiencesection',
      'faq-section'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/guest-centric-revolution`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewCancellationPolicy() {
    this.router.navigateByUrl('blog/what-is-a-hotel-cancellation-policy');
    window.scrollTo(0, 0);
  }

  viewCoreFeaturesOfACrm() {
    this.router.navigateByUrl('blog/benefits-of-crm-in-hotel-industry');
    window.scrollTo(0, 0);
  }

  viewHotelCRM() {
    this.router.navigateByUrl('blog/what-is-a-hotel-crm');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  revolutionIntroduction() {
    this.scrollToSection('AI-In-Hospitality');
  }

  viewBenifits() {
    this.scrollToSection('AIbenifits');
  }

  viewExperience() {
    this.scrollToSection('experiencesection');
  }

  viewFAQ() {
    this.scrollToSection('faq-section');
  }

  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

}