<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Hotel Food Ordering System
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Delight Your Guests, Simplify Your Service</h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Food Ordering System.png" />
            </div>
            <div class="guest-app-child-text">GoFriday’s Hotel Food Ordering System puts your guests at the heart of
                every dining experience.  This intuitive, guest-friendly solution allows your guests to browse menus,
                customize orders, and enjoy seamless service-all from their mobile devices or in-room tablets.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Food Ordering System.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            What is the Gofriday’s Hotel Food Ordering System?
        </h2>
        <div class="guest-app-child-text2">
            The Food Ordering System is an integrated feature within the GoFriday Guest App that enables your guests to
            order food and beverages effortlessly, whenever they wish. Whether it’s in-room dining or table service,
            this system ensures a guest-centric experience while driving efficiency and revenue for your hotel.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Features of the Hotel Food Ordering System
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Digital Menus.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Digital Menus
                    </h3>
                    <div class="why-choose-text">Showcase your restaurant offerings with attractive, customizable
                        digital menus that guests can access anytime.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Real-Time Customization.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Customization
                    </h3>
                    <div class="why-choose-text">Allow guests to modify their orders, choose portion sizes, or add
                        special instructions to fit their unique preferences.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multi-Location Support.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Multi-Location Support
                    </h3>
                    <div class="why-choose-text">Cater to guests ordering from their rooms, poolside, or other hotel
                        areas, ensuring seamless service delivery  wherever they feel most comfortable.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Instant Notifications.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Instant Notifications
                    </h3>
                    <div class="why-choose-text">Notify guests about order confirmations, preparation status, and
                        delivery time updates in real-time.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Secure Payments.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Secure Payments
                    </h3>
                    <div class="why-choose-text">Enable guests to pay directly through the app using secure payment
                        gateways, minimizing hassle.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Upselling Opportunities.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Upselling Opportunities
                    </h3>
                    <div class="why-choose-text">Promote chef’s specials, meal upgrades, or pairing suggestions to
                        enhance guest satisfaction while increasing revenue.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits For Your Hotel
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Enhanced Guest Satisfaction</h3>
                <p>Offer a fast, convenient, and modern dining experience that exceeds expectations.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Operational Efficiency</h3>
                <p>Streamline order management and reduce miscommunication between guests and staff.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Increased Revenue</h3>
                <p>Utilize smart upselling and personalized promotions to boost food and beverage sales.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">04</div>
                <h3>Sustainability</h3>
                <p>Eliminate paper menus and receipts with a fully digital ordering process.</p>
            </div>
        </div>
    </div>
    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Food Ordering System Works.png">
                    </div>
                    <div fxLayout="column" fxFlex="44%" fxLayoutGap="2%">
                        <h2 class="features-heading">
                            How Gofriday’s Hotel Food Ordering System Works
                        </h2>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Explore Menus
                            </div>
                            <div class="features-sub-text">
                                Guests browse digital menus on the GoFriday Guest App or in-room devices.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Place Orders
                            </div>
                            <div class="features-sub-text">
                                They customize their order, select a preferred time, and submit the request.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Instant Processing
                            </div>
                            <div class="features-sub-text">
                                The kitchen receives the order in real time via the GoFriday Task App, ensuring
                                accuracy and
                                speed.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Seamless Delivery
                            </div>
                            <div class="features-sub-text">
                                Staff delivers the food, keeping guests informed through timely updates.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 4% 5% 3% 5%;">
                        <h2 class="features-heading">
                            How Gofriday’s Hotel Food Ordering System Works
                        </h2>
                    </div>
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/Food Ordering System Works.png">
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 15% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Explore Menus
                        </div>
                        <div class="features-sub-text">
                            Guests browse digital menus on the GoFriday Guest App or in-room devices.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Place Orders
                        </div>
                        <div class="features-sub-text">
                            They customize their order, select a preferred time, and submit the request.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Instant Processing
                        </div>
                        <div class="features-sub-text">
                            The kitchen receives the order in real time via the GoFriday Task App, ensuring accuracy
                            and
                            speed.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Seamless Delivery
                        </div>
                        <div class="features-sub-text">
                            Staff delivers the food, keeping guests informed through timely updates.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%" fxLayout="column" fxLayoutGap="2%">
            <div>
                <h2 class="header">Integrated With GoFriday’s Hospitality Suite</h2>
            </div>
            <div class="info-text">
                The Food Ordering System works in harmony with GoFriday’s Task Management App and PMS Dashboard,
                ensuring a cohesive and guest-first workflow  kitchen teams, waitstaff, and management.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/Hospitality Suite.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Integrated With GoFriday’s Hospitality Suite</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/Hospitality Suite.png">
            </div>
            <div class="info-text">
                The Food Ordering System works in harmony with GoFriday’s Task Management App and PMS Dashboard,
                ensuring a cohesive and guest-first workflow  kitchen teams, waitstaff, and management.
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img class="bottom-image" src="assets/images/Dining Experiences.png">
        </div>
        <div fxFlex="50%" fxLayout="column" fxLayoutGap="2%">
            <div>
                <h2 class="header">Elevate Dining Experiences with GoFriday</h2>
            </div>
            <div class="info-text">
                GoFriday’s Food Ordering System ensures effortless, guest-centric, and memorable dining
                experiences. By
                combining guest convenience with operational efficiency, it’s the perfect solution to transform
                your
                hotel’s dining services.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Elevate Dining Experiences with GoFriday</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Dining Experiences.png">
            </div>
            <div class="info-text">
                GoFriday’s Food Ordering System ensures effortless, guest-centric, and memorable dining
                experiences. By
                combining guest convenience with operational efficiency, it’s the perfect solution to transform
                your
                hotel’s dining services.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>