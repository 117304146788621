<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Escalations Management Software
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Resolve Issues Faster and Enhance Guest Satisfaction</h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Escalations Management Software.png" />
            </div>
            <div class="guest-app-child-text">Effective escalation management ensures quick resolutions, seamless
                communication, and improved accountability. The GoFriday Escalations Management system simplifies the
                process to elevate guest experiences.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Escalations Management Software.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Key Features of GoFriday’s Escalations Management System
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Automated Task Scheduling.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Escalation Alerts
                    </h3>
                    <div class="why-choose-text">Receive instant notifications for escalated issues, enabling swift
                        action and resolution.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Smart Notifications.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Customizable Escalation Workflows
                    </h3>
                    <div class="why-choose-text">Define and automate escalation rules based on urgency, department, or
                        service level agreements (SLAs).</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Real-Time Feedback.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Centralized Communication
                    </h3>
                    <div class="why-choose-text">Facilitate seamless communication between teams and departments,
                        ensuring everyone stays informed.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Detailed Escalation Tracking
                    </h3>
                    <div class="why-choose-text">Monitor escalation progress with real-time updates and detailed logs
                        for complete transparency.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multichannel Collection.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Performance Insights and Analytics
                    </h3>
                    <div class="why-choose-text">Analyze trends in escalations to identify bottlenecks and implement
                        process improvements.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits of GoFriday’s Escalations Management System
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Minimize Response Times</h3>
                <p>Address guest concerns quickly to enhance their experience and build trust.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Improve Staff Accountability</h3>
                <p>Track ownership of escalations to ensure every issue is resolved efficiently.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Reduce Operational Disruptions</h3>
                <p>Streamline escalation handling to maintain smooth operations across your property.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">04</div>
                <h3>Enhance Guest Satisfaction</h3>
                <p>Resolve issues proactively and effectively, leaving a positive impression on guests.</p>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <h2 class="guest-app-child-heading2" style="padding: 0% 5% 5% 16%;" *ngIf="!mobile">
                Why Choose GoFriday for Escalations Management software?
            </h2>
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Escalations Management.png">
                    </div>
                    <div fxLayout="column" fxFlex="44%" fxLayoutGap="2%">
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Seamless Integration
                            </div>
                            <div class="features-sub-text">
                                Easily integrates with your existing hotel management systems.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Real-Time Updates
                            </div>
                            <div class="features-sub-text">
                                Stay informed about every escalation without delays.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Customizable Solutions
                            </div>
                            <div class="features-sub-text">
                                Tailor workflows to fit your unique operational needs.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Data-Driven Decisions
                            </div>
                            <div class="features-sub-text">
                                Leverage analytics to continuously improve your processes.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 4% 5% 3% 5%;">
                        <h2 class="features-heading">
                            Why Choose GoFriday for Escalations Management software?
                        </h2>
                    </div>
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/Escalations Management.png">
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 15% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Seamless Integration
                        </div>
                        <div class="features-sub-text">
                            Easily integrates with your existing hotel management systems.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Real-Time Updates
                        </div>
                        <div class="features-sub-text">
                            Stay informed about every escalation without delays.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Customizable Solutions
                        </div>
                        <div class="features-sub-text">
                            Tailor workflows to fit your unique operational needs.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Data-Driven Decisions
                        </div>
                        <div class="features-sub-text">
                            Leverage analytics to continuously improve your processes.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="45%">
            <img class="bottom-image" src="assets/images/Escalations.png">
        </div>
        <div fxFlex="45%" fxLayout="column" fxLayoutGap="1%">
            <div>
                <h2 class="header">Transform Your Hotel <br> Operations Today</h2>
            </div>
            <div class="info-text">
                Effortlessly manage escalations and deliver exceptional service with GoFriday.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Transform Your Hotel Operations Today</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Escalations.png">
            </div>
            <div class="info-text">
                Effortlessly manage escalations and deliver exceptional service with GoFriday.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>