import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../../popup/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog15',
  templateUrl: './blog15.component.html',
  styleUrls: ['./blog15.component.css']
})
export class Blog15Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'What are the benefits of AI in hotels?',
      answer: 'AI enhances hotels by personalizing guest experiences, streamlining operations, and optimizing pricing. It allows for customized room settings and tailored amenities while hotel management software automates tasks like inventory and staff scheduling. Additionally, AI-driven dynamic pricing helps adjust room rates based on demand, ensuring competitive pricing and profitability.',
      open: false
    },
    {
      question: 'How is AI transforming the guest experience in hospitality?',
      answer: 'AI transforms the guest experience through personalization and seamless interactions. It remembers preferences like room temperature and favorite snacks, while AI chatbots provide instant assistance with bookings and recommendations. Predictive analytics also anticipate guest needs, creating a warm and attentive atmosphere.',
      open: false
    },
    {
      question: 'What are some examples of AI in the hospitality industry?',
      answer: `Examples of AI in hospitality include:
      <ul>
        <li><strong>Personalized Experiences:</strong> Tailoring room environments and amenities to guest preferences.</li>
        <li><strong>Chatbots:</strong> Offering 24/7 assistance for inquiries and bookings.</li>
        <li><strong>Dynamic Pricing:</strong> Adjusting rates based on demand.</li>
        <li><strong>Hotel Management Software:</strong>  Automating tasks like inventory and scheduling to improve efficiency.</li>
      </ul>`,
      open: false
    },
    {
      question: 'What are the disadvantages of AI in the hospitality industry?',
      answer: 'Disadvantages of AI include high implementation costs, potential over-reliance that may reduce personal interactions, and data privacy concerns. Hotels must balance AI use with maintaining the personal touch that guests value while ensuring robust security for sensitive information.',
      open: false
    }
  ];

  articles = [
    {
      title: 'What Is A Hotel CRM & How It Maximizes Hotel Success?',
      text: 'Discover how hotel CRM systems can enhance guest experience...',
      image: 'assets/images/CRM-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewHotelCRM()
    },
    {
      title: 'Hotel cancellation policy: everything you need to know',
      text: 'However simple it may sound, booking a hotel can be harder than one imagines...',
      image: 'assets/images/cancellation-policy-image.png',
      readTime: '7 Minutes Read',
      onClick: () => this.viewCancellationPolicy()
    },
    {
      title: 'What is a Hotel Profit Margin?',
      text: 'Operating a hotel in this age is no piece of cake, is it? Everyone would agree that with great rivalry and unending evolution',
      image: 'assets/images/Hotel-Profit-Margin-image.png',
      readTime: '6 Minutes Read',
      onClick: () => this.viewWhatIsHotelProfitMargin()
    },
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Hospitality-Industry',
      'Guest-Experiences',
      'AI-In-Hospitality-Industry',
      'Future-Of-AI',
      'AI-Implementation',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewHotelCRM() {
    this.router.navigateByUrl('blog/what-is-a-hotel-crm');
    window.scrollTo(0, 0);
  }

  viewCancellationPolicy() {
    this.router.navigateByUrl('blog/what-is-a-hotel-cancellation-policy');
    window.scrollTo(0, 0);
  }

  viewWhatIsHotelProfitMargin() {
    this.router.navigateByUrl('blog/hotel-profit-margin');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/ai-in-hospitality-industry`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewHospitalityIndustry() {
    this.scrollToSection('Hospitality-Industry');
  }

  viewGuestExperiences() {
    this.scrollToSection('Guest-Experiences');
  }

  viewAIInHospitalityIndustry() {
    this.scrollToSection('AI-In-Hospitality-Industry');
  }

  viewFutureOfAI() {
    this.scrollToSection('Future-Of-AI');
  }

  viewAIImplementation() {
    this.scrollToSection('AI-Implementation');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
