<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Food & Beverage Management Software
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Iceberg – Food & Beverage (F&B) Management
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Food & Beverage Management.png" />
            </div>
            <div class="guest-app-child-text">Enhance your food and beverage operations with Iceberg - Food & Beverage
                (F&B) Management system. This module streamlines menu management, order processing, inventory tracking,
                and staff coordination, ensuring seamless service and operational efficiency for an exceptional dining
                experience
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Food & Beverage Management.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Efficient Menu Management
        </h2>
        <div class="guest-app-child-text2">
            Create and manage dynamic, high-quality menus that cater to diverse guest preferences. Iceberg’s menu
            management tools ensure your offerings are always up-to-date, aligned with seasonal ingredients, and
            optimized for revenue while keeping guests delighted with fresh and appealing choices.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Digital Menus.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Customizable Menu Creation
                    </h3>
                    <div class="why-choose-text">Design menus that reflect your brand and cuisine to meet guest
                        expectations.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Integrated Payments & Billing.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Dynamic Pricing
                    </h3>
                    <div class="why-choose-text"> Adjust pricing based on demand, peak hours, or ingredient availability
                        to offer guests value at all times.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Seasonal Updates
                    </h3>
                    <div class="why-choose-text">Easily incorporate new dishes and remove items that are out of season.
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="background: #F9F9F9;width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-menu-changes.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="2%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Stay flexible with menu changes, maximize profitability, and keep guests excited about new
                            offerings.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 5% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-menu-changes.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Stay flexible with menu changes, maximize profitability, and keep guests excited about new
                            offerings.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="dynamic-pricing-section">
            <h2 class="guest-app-child-heading2">
                Streamlined Order Processing
            </h2>
            <div class="guest-app-child-text2" style="padding-top: 8px;">
                Enhance order accuracy and speed up service with Iceberg’s integrated order management system. Whether
                dining in, ordering room service, or placing takeout, this system ensures smooth order flow from start
                to finish.
            </div>
            <div class="card-container">
                <mat-card *ngFor="let feature of features"
                    [ngClass]="{'feature-card': !feature.imageOnly, 'image-only-card': feature.imageOnly}"
                    (mouseenter)="onHover(feature)" (mouseleave)="onLeave(feature)"
                    [class.hidden-mobile]="feature.imageOnly && mobile">
                    <!-- Image-only card -->
                    <div *ngIf="feature.imageOnly" class="image-only-content">
                        <img [src]="feature.image" alt="Feature Image" />
                    </div>

                    <!-- Regular card with hover effects -->
                    <div *ngIf="!feature.imageOnly" class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <h3 class="guest-app-child-heading2">
            Inventory & Stock Management
        </h3>
        <div class="guest-app-child-text2" style="padding-top: 8px;">
            Efficiently manage inventory with Iceberg’s comprehensive tracking tools. Ensure that supplies are always in
            stock, reduce waste, and control costs, all while ensuring that guests receive the high-quality dining
            experience they expect.
        </div>
        <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Automated Reordering.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Automated Reordering
                        </div>
                        <div class="knowledge-card-text">
                            Set thresholds to automatically reorder supplies.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Stock Visibility.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Stock Visibility
                        </div>
                        <div class="knowledge-card-text">
                            Monitor stock levels and usage in real time.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Supplier Management.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Supplier Management
                        </div>
                        <div class="knowledge-card-text">
                            Streamline ordering and invoicing processes with supplier integration.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="2%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Prevent stockouts and overstocking, optimize storage space, and lower operational expenses.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image2.png" alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 5% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image2.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Prevent stockouts and overstocking, optimize storage space, and lower operational expenses.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #FFFFFF;">
        <h2 class="guest-app-child-heading2">
            Staff Coordination & Performance
        </h2>
        <div class="guest-app-child-text2">
            Improve communication and collaboration among your F&B team to deliver a seamless service. Iceberg provides
            tools for scheduling, task assignment, and performance tracking all aimed at making service faster and more
            guest-focused.
        </div>
        <!-- </div> -->
        <!-- <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center"> -->
        <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Smart Scheduling.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Smart Scheduling
                        </div>
                        <div class="knowledge-card-text">
                            Automate staff shifts and reduce scheduling conflicts.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Task Management Image.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Task Management
                        </div>
                        <div class="knowledge-card-text">
                            Assign and monitor tasks across the team.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Performance Insights Image.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Performance Insights
                        </div>
                        <div class="knowledge-card-text">
                            Track service times, guest feedback, and productivity metrics.
                        </div>
                    </div>
                </div>
            </mat-card>
            <!-- </div> -->
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-menu-changes1.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="2%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Enhance team productivity, ensure optimal coverage, and maintain consistent service quality.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 5% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-menu-changes1.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Enhance team productivity, ensure optimal coverage, and maintain consistent service quality.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Data-Driven Insights for Success
        </h3>
        <div class="guest-app-child-text2">
            Use Iceberg’s analytics and reporting capabilities to make informed decisions and drive growth. Gain
            insights into sales, popular dishes, peak dining hours, and customer preferences.
        </div>
        <div class="benefits-row" fxLayout="row" fxLayoutAlign="center center">
            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Sales & Revenue Reports.png" alt="Streamlined Operations">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Sales & Revenue Reports</div>
                    <div class="knowledge-card-text">
                        Analyze the profitability of menu items and services.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Guest Feedback Analysis.png" alt="Personalized Service">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Guest Feedback Analysis</div>
                    <div class="knowledge-card-text">
                        Use customer feedback to refine offerings and service.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Performance Metrics.png" alt="Staff Productivity">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Performance Metrics</div>
                    <div class="knowledge-card-text">
                        Track key performance indicators for your F&B operations.
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="2%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Optimize menu offerings, identify trends, and make strategic decisions based on real data.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image3.png" alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 5% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image3.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Optimize menu offerings, identify trends, and make strategic decisions based on real data.
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%" fxLayout="column" fxLayoutGap="2%">
            <div>
                <h2 class="header">Why Iceberg – Food & Beverage (F&B) Management?</h2>
            </div>
            <div class="info-text">
                Iceberg’s F&B Management Module is designed to support a wide range of food and beverage operations,
                from standalone restaurants to hotel dining and room service. With its user-friendly interface and
                powerful features, Iceberg helps you streamline operations, enhance service, and boost guest
                satisfaction.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/Food & Beverage Management1.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Why Iceberg – Food & Beverage (F&B) Management?</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/Food & Beverage Management1.png">
            </div>
            <div class="info-text">
                Iceberg’s F&B Management Module is designed to support a wide range of food and beverage operations,
                from standalone restaurants to hotel dining and room service. With its user-friendly interface and
                powerful features, Iceberg helps you streamline operations, enhance service, and boost guest
                satisfaction.
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img class="bottom-image" src="assets/images/operations with Iceberg.png">
        </div>
        <div fxFlex="45%">
            <div class="info-text">
                Revolutionize your food and beverage operations with Iceberg. Simplify your menu management, streamline
                orders, optimize inventory, and empower your team to deliver a dining experience that guests will
                remember.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/operations with Iceberg.png">
            </div>
            <div class="info-text">
                Revolutionize your food and beverage operations with Iceberg. Simplify your menu management, streamline
                orders, optimize inventory, and empower your team to deliver a dining experience that guests will
                remember.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>