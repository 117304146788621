import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../../popup/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog4',
  templateUrl: './blog4.component.html',
  styleUrls: ['./blog4.component.css']
})
export class Blog4Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'How does a CRM system improve customer relationships?',
      answer: 'A CRM system enhances customer relationships by centralizing and organizing customer data, allowing businesses to track interactions, preferences, and feedback. This enables personalized communication, timely follow-ups, and the ability to anticipate customer needs, leading to stronger, more meaningful relationships and increased customer loyalty.',
      open: false
    },
    {
      question: 'Can a CRM system be customized for my business needs?',
      answer: 'Yes, most CRM systems can be customized to fit the unique needs of your business. Whether you require specific integrations, custom workflows, or tailored reporting, CRM solutions often offer a range of customization options to ensure the system aligns perfectly with your operational processes and business goals.',
      open: false
    },
    {
      question: 'What are the benefits of using AI in a CRM system?',
      answer: 'AI in CRM systems offers several benefits, including predictive analytics for better decision-making, automation of routine tasks like data entry and customer follow-ups, and personalized customer experiences through advanced segmentation and recommendation engines. AI enhances efficiency, improves customer insights, and drives more effective sales and marketing strategies.',
      open: false
    },
    {
      question: 'Is mobile access important for a CRM system?',
      answer: 'Yes, mobile access is crucial for a CRM system, especially in today’s fast-paced business environment. Mobile CRM allows users to access customer data, update records, and communicate with clients from anywhere, improving responsiveness and collaboration. It ensures that your team can stay connected and productive, even when they’re on the go.',
      open: false
    },
    {
      question: 'What are the different types of CRM systems available?',
      answer: `There are three main types of CRM systems:
      <ul>
        <li><strong>Operational CRM:</strong> Focuses on automating and streamlining customer-facing processes, such as sales, marketing, and customer service.</li>
        <li><strong>Analytical CRM:</strong> Centers on analyzing customer data to gain insights and support decision-making, helping businesses understand customer behavior and trends.</li>
        <li><strong>Collaborative CRM:</strong> Aims to improve communication and collaboration between different departments, ensuring a unified approach to customer management across the organization.</li>
      </ul>
      Each type serves a different purpose, and many CRM solutions offer a combination of these functionalities to provide a comprehensive tool for managing customer relationships.
    `,
      open: false
    }
  ];

  articles = [
    {
      title: 'What Is A Hotel CRM & How It Maximizes Hotel Success?',
      text: 'Discover how hotel CRM systems can enhance guest experience...',
      image: 'assets/images/CRM-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewHotelCRMPage()
    },
    {
      title: 'Hotel cancellation policy: everything you need to know',
      text: 'However simple it may sound, booking a hotel can be harder than one imagines...',
      image: 'assets/images/cancellation-policy-image.png',
      readTime: '7 Minutes Read',
      onClick: () => this.viewCancellationPolicy()
    },
    {
      title: 'What is a Hotel Profit Margin?',
      text: 'Operating a hotel in this age is no piece of cake, is it? Everyone would agree that with great rivalry and unending evolution',
      image: 'assets/images/Hotel-Profit-Margin-image.png',
      readTime: '6 Minutes Read',
      onClick: () => this.viewHotelProfitMargin()
    },
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Hotel-CRM',
      'Why-CRM-Essential',
      'Hotel-CRM-Features',
      'Hotel-CRM-Importance',
      'How-To-Choose-CRM',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewCancellationPolicy() {
    this.router.navigateByUrl('blog/what-is-a-hotel-cancellation-policy');
    window.scrollTo(0, 0);
  }

  viewHotelProfitMargin() {
    this.router.navigateByUrl('blog/hotel-profit-margin');
    window.scrollTo(0, 0);
  }

  viewHotelCRMPage() {
    this.router.navigateByUrl('blog/what-is-a-hotel-crm');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/features-of-a-crm-system-for-hotels`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewHotelCRM() {
    this.scrollToSection('Hotel-CRM');
  }

  viewHotelCrmWork() {
    this.scrollToSection('Why-CRM-Essential');
  }

  viewHotelCrmFeatures() {
    this.scrollToSection('Hotel-CRM-Features');
  }

  viewHotelCrmBenefits() {
    this.scrollToSection('Hotel-CRM-Importance');
  }

  viewHotelCrmChoose() {
    this.scrollToSection('How-To-Choose-CRM');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }

  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

}
