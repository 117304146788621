<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Centralized Guest Profiles Management
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Deliver Consistent and Personalized Guest Experiences Across Properties</h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Centralized Guest Profiles Management.png" />
            </div>
            <div class="guest-app-child-text">GoFriday's business intelligence solution streamlines multi-property
                management with real-time dashboards and analytics, empowering teams to boost performance and guest
                satisfaction.Easily manage guest profiles across all properties with GoFriday. Access unified guest data seamlessly to deliver exceptional service and streamline operations.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Centralized Guest Profiles Management.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Key Features of Centralized Guest Profile Management
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/actionable tasks.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Unified Data Across Properties
                    </h3>
                    <div class="why-choose-text">Merge and access guest information from all properties in a single, centralized profile.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Smart Notifications.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Synchronization
                    </h3>
                    <div class="why-choose-text">Ensure guest data is updated instantly across all locations for accurate and consistent service.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multichannel Collection.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Multi-Property Insights
                    </h3>
                    <div class="why-choose-text">Gain a comprehensive view of guest preferences, booking history, and feedback across all properties.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Guest Assistance.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Seamless Inter-Property Transfers
                    </h3>
                    <div class="why-choose-text">Allow guests to enjoy uninterrupted service when moving between properties with shared profiles.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Document Uploads.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Advanced Data Security
                    </h3>
                    <div class="why-choose-text">Protect sensitive guest information with top-tier security and compliance features.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits of Centralized Guest Profile Management
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Consistent Guest Experiences</h3>
                <p>Deliver the same high-quality service, no matter which property your guests visit.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Increased Guest Loyalty</h3>
                <p>Build trust and satisfaction with personalized touches, regardless of location.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Improved Staff Collaboration</h3>
                <p>Provide teams across properties with unified access to guest profiles for better communication.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">04</div>
                <h3>Streamlined Operations</h3>
                <p>Reduce redundancies and eliminate data silos for more efficient property management.</p>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <h2 class="guest-app-child-heading2" style="padding: 0% 5% 5% 16%;" *ngIf="!mobile">
                Why Choose GoFriday for Managing Multi-Property Guest Profiles?
            </h2>
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Guest Profiles Management.png">
                    </div>
                    <div fxLayout="column" fxFlex="44%" fxLayoutGap="2%">
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Scalable Solution
                            </div>
                            <div class="features-sub-text">
                                Designed to grow with your expanding property portfolio.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Seamless Integration
                            </div>
                            <div class="features-sub-text">
                                Compatible with Property Management Systems (PMS) and tools.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                AI-Driven Insights
                            </div>
                            <div class="features-sub-text">
                                Harness AI to predict guest preferences and enhance service delivery.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                User-Friendly Platform
                            </div>
                            <div class="features-sub-text">
                                Easy for staff across all properties to adopt and use.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 4% 5% 3% 5%;">
                        <h2 class="features-heading">
                            Why Choose GoFriday for Managing Multi-Property Guest Profiles?
                        </h2>
                    </div>
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/Guest Profiles Management.png">
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 15% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Scalable Solution
                        </div>
                        <div class="features-sub-text">
                            Designed to grow with your expanding property portfolio.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Seamless Integration
                        </div>
                        <div class="features-sub-text">
                            Compatible with Property Management Systems (PMS) and tools.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            AI-Driven Insights
                        </div>
                        <div class="features-sub-text">
                            Harness AI to predict guest preferences and enhance service delivery.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            User-Friendly Platform
                        </div>
                        <div class="features-sub-text">
                            Easy for staff across all properties to adopt and use.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="45%">
            <img class="bottom-image" src="assets/images/Centralized Guest Profiles.png">
        </div>
        <div fxFlex="45%" fxLayout="column" fxLayoutGap="1%">
            <div>
                <h2 class="header">Elevate Multi-Property Management with Centralized Guest Profiles</h2>
            </div>
            <div class="info-text">
                Experience the power of unified guest data to deliver exceptional service at every property.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Elevate Multi-Property Management with Centralized Guest Profiles</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Centralized Guest Profiles.png">
            </div>
            <div class="info-text">
                Experience the power of unified guest data to deliver exceptional service at every property.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
