import { Component, HostListener, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FeaturesPageComponent } from 'src/app/pages/features-page/features-page.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  mobile: boolean = false;
  currentRoute: string;
  showFeatures: boolean = false;
  guestAppExpanded = false;
  clioExpanded = false;
  taskAppExpanded = false;
  IcebergExpanded = false;
  allInOneExpanded = false;
  multiPropertyExpanded = false;
  public calendlyUrl = environment.CALENDLY_URL;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
    this.currentRoute = this.router.url;
  }

  ngOnInit() {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  resetMenuState() {
    this.showFeatures = false;
    this.guestAppExpanded = false;
    this.clioExpanded = false;
    this.taskAppExpanded = false;
    this.IcebergExpanded = false;
    this.allInOneExpanded = false;
    this.multiPropertyExpanded = false;
  }

  toggleFeatures(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.showFeatures = !this.showFeatures;
  }

  toggleAllInOne(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.allInOneExpanded = !this.allInOneExpanded;
  }

  toggleGuestAppSubMenu(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.guestAppExpanded = !this.guestAppExpanded;
  }

  toggleCLIOSubMenu(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.clioExpanded = !this.clioExpanded;
  }

  toggleIcebergSubMenu(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.IcebergExpanded = !this.IcebergExpanded;
  }

  toggleTaskAppSubMenu(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.taskAppExpanded = !this.taskAppExpanded;
  }

  toggleMultiProperty(event: MouseEvent): void {
    event.stopPropagation(); // Prevent closing the menu
    this.multiPropertyExpanded = !this.multiPropertyExpanded;
  }



  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  selectHome() {
    this.router.navigateByUrl('');
    window.scrollTo(0, 0);
  }

  selectAboutUs() {
    this.router.navigateByUrl('about-us');
    window.scrollTo(0, 0);
  }

  selectFeatures() {
    const dialogRef = this.dialog.open(FeaturesPageComponent, {
      width: '980px',
      height: '386px',
      position: {
        top: '100px',
        left: '323px'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'guest-app') {
        this.router.navigateByUrl('products/hotel-guest-experience-software');
        window.scrollTo(0, 0);
      }
      else if (result == 'task-app') {
        this.router.navigateByUrl('products/hotel-task-management-software');
        window.scrollTo(0, 0);
      }
      else if (result == 'clio') {
        this.router.navigateByUrl('products/clio-hotel-business-intelligence-software');
        window.scrollTo(0, 0);
      }
      else if (result == 'hotel-crm') {
        this.router.navigateByUrl('blog/what-is-a-hotel-crm');
        window.scrollTo(0, 0);
      }
      else if (result == 'revolution') {
        const navigationExtras: NavigationExtras = {
          fragment: 'AI-In-Hospitality'
        };
        this.router.navigate(['/blog/guest-centric-revolution'], navigationExtras).then(() => {

        });
      }
    })
  }

  selectOnlineCheck() {
    this.router.navigateByUrl('products/online-check-in-to-online-check-out');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectInRoomService() {
    this.router.navigateByUrl('products/in-room-services');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectFoodOrdering() {
    this.router.navigateByUrl('products/food-ordering-system');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectFeedback() {
    this.router.navigateByUrl('products/360-feedback');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectEconcierge() {
    this.router.navigateByUrl('products/virtual-concierge-services');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectGuestRequest() {
    this.router.navigateByUrl('products/guest-request-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectPerformanceManagement() {
    this.router.navigateByUrl('products/performance-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectPropertyManagement() {
    this.router.navigateByUrl('products/property-management-system');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectFeedbackManagement() {
    this.router.navigateByUrl('products/feedback-management-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectTaskManagement() {
    this.router.navigateByUrl('products/task-management-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectEscalationsManagement() {
    this.router.navigateByUrl('products/escalations-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectSpaManagementModule() {
    this.router.navigateByUrl('products/spa-management-module');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectFoodManagement() {
    this.router.navigateByUrl('products/food-beverage-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectConferenceManagement() {
    this.router.navigateByUrl('products/e-conference-room-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectConciergeManagement() {
    this.router.navigateByUrl('products/e-concierge-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectHousekeepingManagement() {
    this.router.navigateByUrl('products/housekeeping-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectMarketplace() {
    this.router.navigateByUrl('products/marketplace');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectReservationManagement() {
    this.router.navigateByUrl('products/centralized-reservation-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectCentralisedGuestProfiles() {
    this.router.navigateByUrl('products/centralized-guest-profile-management');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectCentralisedBusinessIntelligence() {
    this.router.navigateByUrl('products/centralized-business-intelligence');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectGuestApp() {
    this.router.navigateByUrl('products/hotel-guest-experience-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectCLIO() {
    this.router.navigateByUrl('products/clio-hotel-business-intelligence-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectTaskApp() {
    this.router.navigateByUrl('products/hotel-task-management-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectBlog() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  selectPricingPlans() {
    this.router.navigateByUrl('plans');
    window.scrollTo(0, 0);
  }

  selectPartnerProgram() {
    this.router.navigateByUrl('partner-program');
    window.scrollTo(0, 0);
  }

  selectExperiences() {
    this.router.navigateByUrl('experiences');
    window.scrollTo(0, 0);
  }

}