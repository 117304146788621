<app-header></app-header>
<div class="container">
  <div class="hero-banner width100" fxLayout="column" fxLayoutGap="1%">
    <h1 class="hero-banner-child-heading">
      Hotel Management Software
    </h1>
    <div>
      <h2 class="hero-banner-child-heading2">The Modern Platform for Guest Centric Hospitality</h2>
    </div>
    <div *ngIf="mobile">
      <img class="dashbord-image" src="assets/mobile-images/Hotel-Management-Image.png">
    </div>
    <div>
      <span class="hero-banner-child-cont">GoFriday brings you futuristic technology designed to prioritize guest
        satisfaction while simplifying hotel operations.</span>
    </div>
    <div class="buttons">
      <button class="book-btn" (click)="openCalendlyPopup()">
        Book a Demo
      </button>
      <button class="demo-link" (click)="selectTrial()">
        Talk to Us
        <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
      </button>
    </div>
    <!-- <div class="dashbord-image" *ngIf="!mobile" [@imageTransition]="state">
      <img class="home-dashboard" src="assets/images/home-dashboard.png">
    </div> -->

    <!-- <div class="homepage-container">
      <div class="gray-box">
        <h1>
          <span class="hero-banner-child-heading2" [@letterAnimation]="animationState">{{ headerWord }}</span>
        </h1>
        <div *ngIf="showVideo" class="video-container">
          <video autoplay muted loop class="hotel-video">
            <source src="assets/video/unlock-new-possibilities.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="image-wrapper" [@imageAnimation]="imageState">
          <img [src]="mobileImage" alt="Mobile" class="center-image" />
        </div>
      </div>
    </div> -->

    <div class="animation-container" *ngIf="!mobile">
      <div class="background-container">
        <div class="background-content">
          <video #videoPlayer autoplay muted playsinline>
            <source src="assets/videos/Home-Video.mp4" type="video/mp4" />
          </video>
        </div>
        <h1 class="animated-text" [class.show-text]="showText" [ngClass]="textColor">
          <span *ngFor="let char of textArray; let i = index" [ngStyle]="{ '--delay': (i * 0.05) + 's' }">
            {{ char }}
          </span>
        </h1>
        <div *ngIf="showFeatures" [@featureAnimation] class="features-container">
          <div class="feature-group left-features">
            <div fxLayout="row">
              <div class="feature-label top-left">
                Guest experience
              </div>
              <div class="feature-line"><img src="/assets/images/left-vector.png" alt=""
                  class="vector top-left-vector" /> </div>
            </div>

            <div fxLayout="row">
              <div class="feature-label middle-left">
                Transport
              </div>
              <div class="feature-line"><img src="/assets/images/left-vector.png" alt=""
                  class="vector middle-left-vector" /> </div>
            </div>

            <div fxLayout="row">
              <div class="feature-label bottom-left">
                Multi property management
              </div>
              <div class="feature-line"><img src="/assets/images/left-vector.png" alt=""
                  class="vector bottom-left-vector" /> </div>
            </div>
          </div>

          <!-- Right side features -->
          <div class="feature-group right-features">
            <div fxLayout="row">
              <div class="feature-label top-right">
                Spa
              </div>
              <div class="feature-line"><img src="/assets/images/right-vector.png" alt=""
                  class="vector top-right-vector" /> </div>
            </div>

            <div fxLayout="row">
              <div class="feature-label middle-right">
                Housekeeping
              </div>
              <div class="feature-line"><img src="/assets/images/right-vector.png" alt=""
                  class="vector middle-right-vector" /> </div>
            </div>

            <div fxLayout="row">
              <div class="feature-label bottom-right">
                Property management
              </div>
              <div class="feature-line"><img src="/assets/images/right-vector.png" alt=""
                  class="vector bottom-right-vector" /> </div>
            </div>
          </div>
        </div>

        <div class="mobile-container" [@mobileAnimation]="animationState1" [style.left]="mobilePosition.x"
          [style.top]="mobilePosition.y"
          [style.transform]="'translate(-50%, -50%) scale(' + mobilePosition.scale + ')'">
          <img [src]="currentMobileImage" alt="Mobile Interface" class="mobile-image" />
        </div>


      </div>
    </div>


  </div>

  <div class="why-choose-container width100" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="hero-banner-child-heading2">
      Why Choose GoFriday Hotel Management Software
    </h2>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="4%" class="why-choose">
        <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
          <img class="choose-image1" src="/assets/images/unified-img.png" alt="" />
          <h3 class="why-choose-content-head">
            UNIFIED PLATFORM
          </h3>
          <div class="why-choose-text">Consolidate operations in one platform for real-time data visibility</div>
        </div>

        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/integrated-img.png" alt="" />
          <h3 class="why-choose-content-head">
            FULLY INTEGRATED
          </h3>
          <div class="why-choose-text">One-stop toolset and an open API for easy connections</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/guest-img.png" alt="" />
          <h3 class="why-choose-content-head">
            GUEST-CENTRICITY
          </h3>
          <div class="why-choose-text">Capture guest preferences through CRM</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/workflow-img.png" alt="" />
          <h3 class="why-choose-content-head">
            AUTOMATED WORKFLOWS
          </h3>
          <div class="why-choose-text">Automate operations and guest communications</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/business-img.png" alt="" />
          <h3 class="why-choose-content-head">
            BUSINESS INTELLIGENCE
          </h3>
          <div class="why-choose-text">Real-time insights for smarter business</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/cloud-img.png" alt="" />
          <h3 class="why-choose-content-head">
            SCALABLE & SECURE CLOUD INFRASTRUCTURE
          </h3>
          <div class="why-choose-text">Secure cloud access, anytime, anywhere</div>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-container width100" fxLayout="column" fxLayoutGap="1%">
    <h2 class="hero-banner-child-heading2">
      A Unified Platform Built for Hospitality
    </h2>
    <div>
      <span class="sub-content">Say goodbye to fragmented tools and complicated workflows. GoFriday
        integrates every aspect of hotel operations, empowering your team to focus on what truly matters: <span
          class="container-sub-header">Your
          Guests</span></span>
    </div>
    <div>
      <button class="book-btn" (click)="openCalendlyPopup()">
        Book a Demo
      </button>
    </div>
  </div>

  <div class="wave">
    <img src="assets/images/wave.png">
  </div>
  <div class="powerhouse-container width100" fxLayout="column" fxLayoutAlign="center center">
    <div class="hospitality-heading">
      Managing Operations For Hotels Internationally, GoFriday Is A Hospitality Powerhouse
    </div>
    <div fxLayout="column" *ngIf="mobile" style="padding-top: 8%;">
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/icon-park-outline_check-in.png">
            <div class="card-content">
              85%
            </div>
          </div>
          <div class="card-text-header">Faster Check-Ins</div>
          <div class="card-text-content">Eliminate long queues and reduce guest wait times</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
            <img src="assets/images/solar_users-group-rounded-broken.png">
            <div class="card-content">
              15 Minutes
            </div>
          </div>
          <div class="card-text-header">Saved Per Reservation</div>
          <div class="card-text-content">Automate processes to boost efficiency and productivity.</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
            <img src="assets/images/tabler_bed-flat.png">
            <div class="card-content">
              $75 Average
            </div>
          </div>
          <div class="card-text-header">Uplift Per Bed/Month</div>
          <div class="card-text-content">Maximize revenue with tailored upsell opportunities</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/card-image.png">
            <div class="card-content">
              360°
            </div>
          </div>
          <div class="card-text-header">Guest Insights</div>
          <div class="card-text-content">Collect, analyze, and act on guest feedback seamlessly.</div>
        </div>
      </mat-card>
    </div>
  </div>
  <div style="background: #F9F9F9;">
    <div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign="center" class="sub-card-row" *ngIf="!mobile">
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/icon-park-outline_check-in.png">
            <div class="card-content">
              85%
            </div>
          </div>
          <div class="card-text-header">Faster Check-Ins</div>
          <div class="card-text-content">Eliminate long queues and reduce guest wait times</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
            <img src="assets/images/solar_users-group-rounded-broken.png">
            <div class="card-content">
              15 Minutes
            </div>
          </div>
          <div class="card-text-header">Saved Per Reservation</div>
          <div class="card-text-content">Automate processes to boost efficiency and productivity.</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
            <img src="assets/images/tabler_bed-flat.png">
            <div class="card-content">
              $75 Average
            </div>
          </div>
          <div class="card-text-header">Uplift Per Bed/Month</div>
          <div class="card-text-content">Maximize revenue with tailored upsell opportunities</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/card-image.png">
            <div class="card-content">
              360°
            </div>
          </div>
          <div class="card-text-header">Guest Insights</div>
          <div class="card-text-content">Collect, analyze, and act on guest feedback seamlessly.</div>
        </div>
      </mat-card>
    </div>
    <!-- <div fxLayoutAlign="center">
      <video #mainVideo (click)="toggleVideo()" class="video1" controls [poster]="poster" [muted]="isMuted">
        <source src="assets/videos/unlock-new-possibilities.mp4" type="video/mp4" />
      </video>
    </div> -->
  </div>


  <div fxLayout="row" class="sub-container1 width100" fxLayoutGap="4%" fxLayoutAlign="center center" *ngIf="!mobile">
    <div class="sub-container-text" fxFlex="34%">
      From Check-In to Check-Out Solutions That Elevate Every Experience
    </div>
    <div class="arrow-div">
      <iconify-icon icon="mdi-light:arrow-right" width="24" height="24"></iconify-icon>
    </div>
    <div class="sub-content-text" fxFlex="60%">
      Deliver a superior guest experience from start to finish with effortless check-ins, personalized service, and
      stress-free check-outs, all while achieving operational excellence and driving upsells.
    </div>
  </div>

  <div fxLayout="column" class="sub-container1 width100" fxLayoutGap="4%" fxLayoutAlign="center center" *ngIf="mobile">
    <div class="sub-container-text">
      From Check-In to Check-Out Solutions That Elevate Every Experience
    </div>
    <div class="sub-content-text">
      Deliver a superior guest experience from start to finish with effortless check-ins, personalized service, and
      stress-free check-outs, all while achieving operational excellence and driving upsells.
    </div>
  </div>

  <!-- Top Section -->
  <div class="journey-section">
    <div class="content-grid">
      <div class="outer-card">
        <div class="feature-block">
          <div fxLayout="row" fxLayoutGap="2%">
            <div class="card-box1"></div>
            <h2 class="card-content-header">The Journey Begins: Effortless Check-in And Check-Out</h2>
          </div>
          <p class="subtitle">Let guests check in and out seamlessly from their smartphones</p>
          <div class="feature-item">
            <button class="products-button" (click)="selectOnlineCheckInCheckOut()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Online Check-In To Online Check-Out Software
            </button>
          </div>
          <div class="mobile-preview">
            <img src="/assets/images/check-in-app.png" alt="Mobile app interface" class="app-screenshot" />
          </div>
        </div>
      </div>


      <div class="outer-card">
        <div class="feature-block">
          <div fxLayout="row" fxLayoutGap="2%">
            <div class="card-box"></div>
            <h2 class="card-content-header">Enhancing The Stay: Personalized Guest Services</h2>
          </div>
          <p class="subtitle">Delight your guests with personalized care and see an extra $75* per room each month</p>
          <div class="service-list">
            <div class="feature-item">
              <button class="products-button1" (click)="selectInRoomServices()">
                <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
                In-Room Services
              </button>
              <button class="products-button1" (click)="selectFoodOrderingSystem()">
                <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
                Food Ordering System
              </button>
              <button class="products-button1" (click)="selectEConciergeManagement()">
                <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
                E-Concierge Management
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Bottom Section -->
  <div class="operations-container">
    <div class="operations-section">
      <div class="content-wrapper">
        <div class="operations-grid" fxLayout="column">
          <h2>Behind The Scenes: Streamlined Operations</h2>
          <p class="subtitle">Bring order to your operations and save 15 minutes* per reservation with smart tools.
          </p>
          <div class="feature-item">
            <button class="products-button1" (click)="selectTaskManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Task Management
            </button>
            <button class="products-button1" (click)="selectHousekeepingManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Housekeeping Management
            </button>
            <button class="products-button1" (click)="selectPropertyManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Property Management
            </button>
            <button class="products-button1">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Front Desk Management
            </button>
            <button class="products-button1" (click)="selectCentralizedReservationManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Centralized Reservation Management
            </button>
          </div>
        </div>
        <div class="app-preview">
          <img src="/assets/images/app-screen.png" alt="Management interface" class="app-screenshot" />
        </div>
      </div>
    </div>
  </div>

  <div class="experiences-container">
    <div class="experiences-section">
      <div class="content-wrapper">
        <div class="experiences-grid" fxLayout="column">
          <h2>Elevating Experiences: Dining, Wellness, and Event</h2>
          <p class="subtitle">Turn every moment—dining, spa, or events—into a memorable experience for your guests.
          </p>
          <div class="feature-item">
            <button class="products-button1" (click)="selectFoodandBeverage()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Food & Beverage (F&B) Management
            </button>
            <button class="products-button1" (click)="selectSpaManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Spa Management Module
            </button>
            <button class="products-button1" (click)="selectEConferenceRoomManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              E-Conference Room Management
            </button>
          </div>
        </div>
        <div class="experiences-preview">
          <img src="/assets/images/experiences-image.png" class="app-screenshot" />
        </div>
      </div>
    </div>
  </div>

  <div class="feedback-container">
    <div class="feedback-section">
      <div class="content-wrapper">
        <div class="feedback-preview" *ngIf="!mobile">
          <img src="/assets/images/feedback-image.png" class="app-screenshot" />
        </div>
        <div class="experiences-grid" fxLayout="column">
          <h2>After the Stay: Feedback & Loyalty</h2>
          <p class="subtitle">Collect and act on guest reviews to continually improve services.</p>
          <div class="feature-item">
            <button class="products-button1" (click)="selectFeedbackManagement()">
              <iconify-icon icon="ei:arrow-right" width="25" height="25" class="card-arrow-icon"></iconify-icon>
              Feedback Management
            </button>
          </div>
          <div class="feedback-preview" *ngIf="mobile">
            <img src="/assets/images/feedback-image.png" class="app-screenshot" />
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="visual-container width100" fxLayout="column">
    <h2 class="hero-banner-child-heading2">
      Watch It Work: A Visual Guide to our products
    </h2>
    <div class="visual-content" fxLayout="column" *ngIf="!mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
          <h3 class="visual-header"><a [href]="guestAppLink" class="anchor-text">
              Guest App</a></h3>
          <div class="visual-sub-text-contents">
            <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
            <span class="visual-sub-text">Easy-to-use Guest-Centric App</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2%">
          <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start">
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Simple App, Happy Guests</div>
                <div class="info-desc">See your entire hotel in one place</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Boost Revenue with Upsells</div>
                <div class="info-desc">Maximize guest spending with app exclusives</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Real-time Feedback </div>
                <div class="info-desc">Listen, learn, improve with in-app surveys</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Live Service Tracking</div>
                <div class="info-desc">Instant visibility into service status</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Mobile Concierge</div>
                <div class="info-desc">Explore, dine, discover, and book - all in one app</div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
            <div fxFlex="60%" fxLayoutAlign=" center">
              <video #guestVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/guest-app.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
          <h3 class="visual-header"><a [href]="guestAppLink" class="anchor-text">Guest
              App</a></h3>
        </div>
        <span class="visual-sub-text">Easy-to-use Guest-Centric App</span>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
          <div fxLayoutAlign=" center">
            <video #guestVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/guest-app.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Simple App, Happy Guests</div>
              <div class="info-desc">See your entire hotel in one place</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Boost Revenue with Upsells</div>
              <div class="info-desc">Maximize guest spending with app exclusives</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Real-time Feedback </div>
              <div class="info-desc">Listen, learn, improve with in-app surveys</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Live Service Tracking</div>
              <div class="info-desc">Instant visibility into service status</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Mobile Concierge</div>
              <div class="info-desc">Explore, dine, discover, and book - all in one app</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="visual-content" fxLayout="column" *ngIf="!mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
          <h3 class="visual-header"><a [href]="clioAppLink" class="anchor-text">
              CLIO – Admin Panel</a></h3>
          <div class="visual-sub-text-contents">
            <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
            <span class="visual-sub-text">This intuitive AI – Powered admin panel is your ultimate Command
              Center</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2%">
          <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start">
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Automated & Real-time Insights</div>
                <div class="info-desc">Proactive service through real-time guest insights</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Data-Driven Decisions</div>
                <div class="info-desc">Make informed decisions with actionable metrics</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Task Escalation & Role Assignment</div>
                <div class="info-desc">Assign roles and automate task escalation for urgent requests</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">360 degree tracking</div>
                <div class="info-desc">Track all guest needs and inventory</div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
            <div fxFlex="60%" fxLayoutAlign=" center">
              <video #clioVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
          <h3 class="visual-header"><a [href]="clioAppLink" class="anchor-text">
              CLIO – Admin Panel</a></h3>
        </div>
        <span class="visual-sub-text">This intuitive AI – Powered admin panel is your ultimate Command Center</span>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
          <div fxLayoutAlign=" center">
            <video #clioVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Automated & Real-time Insights</div>
              <div class="info-desc">Proactive service through real-time guest insights</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Data-Driven Decisions</div>
              <div class="info-desc">Make informed decisions with actionable metrics</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Task Escalation & Role Assignment</div>
              <div class="info-desc">Assign roles and automate task escalation for urgent requests</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">360 degree tracking</div>
              <div class="info-desc">Instant visibility Task Escalation & Role Assignmentinto service status</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="visual-content" fxLayout="column" *ngIf="!mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
          <h3 class="visual-header"><a [href]="taskAppLink" class="anchor-text">
              Task App</a></h3>
          <div class="visual-sub-text-contents">
            <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
            <span class="visual-sub-text">Next-Level Task Management: Simplify Operations</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2%">
          <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start">
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Stay Compliant, Stay Ahead</div>
                <div class="info-desc">Automate compliance with SLAs and SOPs </div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Smarter Workflows</div>
                <div class="info-desc">Automate routine tasks, focus on guests</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Guest Requests at Fingertips</div>
                <div class="info-desc">Instant and comprehensive visibility into guest requests</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Celebrate Success</div>
                <div class="info-desc">Recognize, reward, and motivate staff</div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
            <div fxFlex="60%" fxLayoutAlign=" center">
              <video #taskVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/task-app.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
          <h3 class="visual-header"><a [href]="taskAppLink" class="anchor-text">
              Task App</a></h3>
        </div>
        <span class="visual-sub-text">Next-Level Task Management: Simplify Operations</span>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
          <div fxLayoutAlign=" center">
            <video #taskVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/task-app.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Stay Compliant, Stay Ahead</div>
              <div class="info-desc">Automate compliance with SLAs and SOPs </div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Smarter Workflows</div>
              <div class="info-desc">Automate routine tasks, focus on guests</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Guest Requests at Fingertips</div>
              <div class="info-desc">Instant and comprehensive visibility into guest requests</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Celebrate Success</div>
              <div class="info-desc">Recognize, reward, and motivate staff</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div> -->

  <div class="knowledge-container width100" fxLayout="column">
    <div class="knowledge-header-contents">
      <h2 class="hero-banner-child-heading3">
        Stay Ahead with Expert Tips and Trends
      </h2>
      <div class="hero-banner-text-container">
        <span class="hero-banner-child-text">
          Explore our latest blogs to discover actionable strategies, industry trends, and tips to revolutionize your
          <a [href]="hotelManagementLink" class="anchor-text">hotel management systems</a>
        </span>
      </div>
    </div>
    <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
      <mat-card class="knowledge-card" (click)='selectHicsa()'>
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/HICSA-image.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              Exploring Automated Hotel
            </div>
            <div class="knowledge-card-text">
              Ever wondered how those fancy hotels seem to know exactly what you want before you even ask? It's all
              thanks to a magical....
            </div>
          </div>
          <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;<span
              class="knowledge-card-text">(4 Minutes Read)</span></div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card" (click)='selectGuestCentricRevolution()'>
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/guest-image.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              The Guest-Centric Revolution
            </div>
            <div class="knowledge-card-text">
              Artificial Intelligence (AI) has transitioned from a futuristic concept to....
            </div>
          </div>
          <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;<span
              class="knowledge-card-text">(3 Minutes Read)</span></div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card" (click)='selectHotelCRM()'>
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/CRM-image.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              What Is A Hotel CRM & How It Maximizes Hotel Success?
            </div>
            <div class="knowledge-card-text">
              Discover how hotel CRM systems can enhance guest experience...
            </div>
          </div>
          <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;<span
              class="knowledge-card-text">(3 Minutes Read)</span></div>
        </div>
      </mat-card>
    </div>
  </div>

  <div fxLayout="row" class="guest-experience-container width100" fxLayoutAlign="center center" *ngIf="!mobile">
    <div class="contact-image" fxFlex="50%">
      <img src="assets/images/guest-experience.png">
    </div>
    <div class="guest-experience-content" fxLayout="column" fxFlex="50%">
      <h2 class="guest-heading">
        Every Hotel Is Unique. <br>
        So are Our Solutions.
      </h2>
      <div class="guest-text">
        Let us craft a personalized solution for your hotel’s needs.
      </div>
      <div class="buttons" style="margin-top: 3%;">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book a Demo
        </button>
        <button class="demo-link" (click)="selectTrial()">
          Talk to Us
          <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="guest-experience-container width100" fxLayoutAlign="center center" *ngIf="mobile">
    <div class="guest-experience-content">
      <h2 class="guest-heading">
        Every Hotel Is Unique. So are Our Solutions.
      </h2>
      <div class="contact-image">
        <img src="assets/mobile-images/guest-experience.png">
      </div>
      <div class="guest-text">
        Let us craft a personalized solution for your hotel’s needs.
      </div>
      <div class="buttons" style="margin-top: 3%; display: flex; justify-content: center;">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book a Demo
        </button>
        <button class="demo-link" (click)="selectTrial()">
          Talk to Us
          <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
        </button>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="!mobile">
    <div>
      <h2 class="faq-header">FAQ’S</h2>
    </div>
    <div fxLayout="column" class="faq-content">
      <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
          {{ faq.question }}
          <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="mobile">
    <div>
      <h2 class="faq-header">FAQ’S</h2>
    </div>
    <div fxLayout="column" class="faq-content">
      <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
          {{ faq.question }}
          <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="info-container width100">
    <div fxLayout="column" class="info-container-content" *ngIf="!mobile">
      <div>
        <h2 class="info-heading1">Unlock New Possibilities With Us</h2>
      </div>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start" [@cardIn]="cardAnimationState">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="4%">
              <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
                <div>
                  <img src="assets/images/benefit3.png">
                </div>
                <div class="info-head1">Multiply Revenue</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Intelligent recommendations for maximizing earning opportunities</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Centralized data insights for cross-selling and up-selling</div>
              </div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="4%">
              <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
                <div>
                  <img src="assets/images/benefit2.png">
                </div>
                <div class="info-head1">Streamline Operations</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Streamline operations with AI, prompt visibility and seamless tracking</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Reduce your operational costs and enhance overall efficiency</div>
              </div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="4%">
              <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
                <div>
                  <img src="assets/images/benefit1.png">
                </div>
                <div class="info-head1">Elevate Guest Experience</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Analyze guest interactions, preferences and feedback</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Gather real-time insights for operational improvements</div>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="2%" [@videoIn]="videoAnimationState">
          <div fxFlex="60%" fxLayoutAlign=" center">
            <video #infoVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/unlock-new-possibilities.mp4" type="video/mp4" />
            </video>
          </div>
         
        </div>
      </div>
    </div>
    <div class="info-container-child" *ngIf="mobile">
      <div fxLayout="column" fxLayoutGap="5%">
        <div>
          <h2 class="info-heading1">Unlock New Possibilities With Us</h2>
        </div>
        <mat-card class="info-card">
          <div fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
              <div>
                <img src="assets/images/benefit3.png">
              </div>
              <div class="info-head1">Multiply Revenue</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Intelligent recommendations for maximizing earning opportunities</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Centralized data insights for cross-selling and up-selling</div>
            </div>
          </div>
        </mat-card>
        <mat-card class="info-card">
          <div fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
              <div>
                <img src="assets/images/benefit2.png">
              </div>
              <div class="info-head1">Streamline Operations</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Streamline operations with AI, prompt visibility and seamless tracking</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Reduce your operational costs and enhance overall efficiency</div>
            </div>
          </div>
        </mat-card>
        <mat-card class="info-card">
          <div fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
              <div>
                <img src="assets/images/benefit1.png">
              </div>
              <div class="info-head1">Elevate Guest Experience</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Analyze guest interactions, preferences and feedback</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Gather real-time insights for operational improvements</div>
            </div>
          </div>
        </mat-card>
        <div fxLayout="column" fxLayoutAlign="center" style="margin-top: 8%;">
          <video #infoVideo (click)="toggleVideo()" class="video" controls [poster]="poster" style="width: 100%;"
            [muted]="isMuted">
            <source src="assets/videos/unlock-new-possibilities.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <div class="division1" *ngIf="!mobile">
      </div>
      <div class="division2" *ngIf="!mobile">
      </div>
      <div class="card-container">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <span class="info-head2">The <span class="info-head-text">GoFriday</span> Triad</span>
        </div>
        <div fxLayout="column">
          <div #animationArea class="card-row" fxLayout="row" fxLayoutAlign="space-evenly center">
            <mat-card class="card" (click)="getVideo(video1)" (mouseenter)="getVideo(video1)"
              (mouseleave)="stopVideo(video1)" [@card1In]="card1AnimationState">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="38%" class="heading info-head3">
                  <video #video1 oncontextmenu="return false;" class="video" controls [poster]="poster1"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/guest-app.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="8%" class="card-header-body">
                  Guest App
                </div>
                <div class="card-body">
                  Optimize Every Stay, Maximize Every Pay. Elevate Up-selling, Empower Guests with Real-Time Request
                  Tracking and Feedback. Transform Legacy process flows.
                </div>
              </div>
            </mat-card>
            <mat-card class="card-middle" *ngIf="!mobile" (click)="getVideo(video2)" (mouseenter)="getVideo(video2)"
              (mouseleave)="stopVideo(video2)" [@card2In]="card2AnimationState">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="28%" class="heading info-head3">
                  <video #video2 oncontextmenu="return false;" class="video" controls [poster]="poster2"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="8%" class="card-header-body">
                  Clio - AI Powered Admin Panel
                </div>
                <div class="card-body">
                  A centralized panel to view, manage, and optimize guest requests, reducing human resource dependency
                  and
                  leading to a significant decrease in operational costs.
                </div>
              </div>
            </mat-card>
            <mat-card class="card" *ngIf="mobile" (click)="getVideo(video2)" (mouseleave)="stopVideo(video2)">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="28%" class="heading info-head3">
                  <video #video2 oncontextmenu="return false;" class="video" controls [poster]="poster2"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="15%" class="card-header-body">
                  Clio - AI Powered Admin Panel
                </div>
                <div class="card-body">
                  A centralized panel to view, manage, and optimize guest requests, reducing human resource dependency
                  and
                  leading to a significant decrease in operational costs.
                </div>
              </div>
            </mat-card>
            <mat-card class="card" (mouseenter)="getVideo(video3)" (click)="getVideo(video3)"
              (mouseleave)="stopVideo(video3)" [@card3In]="card3AnimationState">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="28%" class="heading info-head3">
                  <video #video3 oncontextmenu="return false;" class="video" controls [poster]="poster3"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/task-app.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="8%" class="card-header-body">
                  Task App
                </div>
                <div class="card-body">
                  Maximize efficiency with 360-degree tracking, ensuring real-time visibility, SOP adherence, and
                  streamlined SLA efficiency. Boost productivity through comprehensive monitoring for enhanced
                  performance.
                </div>
              </div>
            </mat-card>
          </div>
      </div>
    </div>
  </div>

  <div class="hicsa-container width100" *ngIf="!mobile">
    <div fxLayout="row" fxLayoutGap="1%" class="hicsa-content" fxLayoutAlign="start start">
      <div fxLayout="column" fxFlex="35%" fxLayoutGap="2%">
        <h2 class="hicsa-heading">GoFriday At HICSA’ 2024</h2>
        <span class="hicsa-text">
          Attending the recent HICSA (Hotel Investment Conference - South Asia) 2024 conference in Bengaluru, GoFriday
          found themselves surrounded by hospitality leaders and innovators at this dynamic industry event.
        </span>
        <span style="margin-top: 5px;">
          <button class="book-btn" (click)="selectBlog()">
            Read More</button></span>
      </div>
      <div fxFlex="70%" class="hicsa-img">
        <img src="assets/images/Hicsa 2024.png">
        <span class="read-more">
          <button class="read-btn" (click)="selectBlog()">
            Read More</button></span>
      </div>
    </div>
    <div class="hicsa-images">
      <div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign="space-between">
        <div class="hicsa-img1">
          <img src="assets/images/Hicsa-img1.png">
          <span class="read-more">
            <button class="read-btn" (click)="selectCorrelation()">
              Read More</button></span>
        </div>
        <div class="hicsa-img2">
          <img src="assets/images/Hicsa-img2.png">
          <span class="read-more">
            <button class="read-btn" (click)="selectCloud()">
              Read More</button></span>
        </div>
        <div class="hicsa-img3">
          <img src="assets/images/Hicsa-img3.png">
          <span class="read-more">
            <button class="read-btn" (click)="selectRevenue()">
              Read More</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="hicsa-container width100" *ngIf="mobile">
    <div fxLayout="column" fxLayoutAlign=" center">
      <h2 class="hicsa-heading">GoFriday At HICSA’ 2024</h2>
      <div><img src="assets/mobile-images/Hicsa-2024.png"></div>
      <div><img src="assets/mobile-images/Hicsa-img1.png" (click)="selectCorrelation()"></div>
      <div><img src="assets/mobile-images/Hicsa-img2.png" (click)="selectCloud()"></div>
      <div><img src="assets/mobile-images/Hicsa-img3.png" (click)="selectRevenue()"></div>
      <div class="hicsa-mob-content" fxLayoutAlign=" center">
        <span class="hicsa-text">
          Attending the recent HICSA (Hotel Investment Conference - South Asia) 2024 conference in Bengaluru, GoFriday
          found themselves surrounded by hospitality leaders and innovators at this dynamic industry event.
        </span>
      </div>
      <button class="book-btn" (click)="selectBlog()">
        Read More</button>
    </div>
  </div>
  <div class="headLineContainer width100" fxLayout="column">
  </div>
  <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
      <div>
        <p class="single-line">We're always happy to hear from you.</p>
      </div>
      <div>
        <img src="assets/images/contact-us.png">
      </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
      <div class="form-heading">
        Contact Us
      </div>
      <div class="form">
        <form [formGroup]="contactUsForm">
          <div fxLayout="column" fxLayoutGap="5%">
            <div>
              <input type="text" class="text-box" formControlName="name" placeholder="Full Name" maxlength="200"
                autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div>
              <input type="text" class="text-box-email" maxlength="200" formControlName="email" placeholder="Email"
                autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                Required
              </mat-error>
              <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                Please provide a valid Email Id
              </mat-error>
            </div>
            <div>
              <textarea class="text-area" formControlName="message" placeholder="Write message" maxlength="1500"
                autocomplete="off"></textarea>
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div fxLayout="row" fxLayoutGap="2%">
              <input type="checkbox" class="check-box" formControlName="tnc" />
              <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                  (click)="viewPrivacyPolicy()">"Privacy
                  Policy"</a> and
                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                  Conditions"</a></span>
            </div>
          </div>
        </form>
      </div>
      <div style="text-align: center;">
        <button
          [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
          (click)="submitContactForm()"
          [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
          <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
      Contact Us
    </div>
    <div>
      <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
      <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
      <div class="form">
        <form [formGroup]="contactUsForm">
          <div fxLayout="column" fxLayoutGap="5%">
            <div>
              <input type="text" class="text-box" formControlName="name" placeholder="Full Name" maxlength="200"
                autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div>
              <input type="text" class="text-box-email" formControlName="email" placeholder="Email" autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                Required
              </mat-error>
              <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                Please provide a valid Email Id
              </mat-error>
            </div>
            <div>
              <textarea class="text-area" formControlName="message" placeholder="Write message" maxlength="1500"
                autocomplete="off"></textarea>
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div fxLayout="row" fxLayoutGap="2%">
              <input type="checkbox" class="check-box" formControlName="tnc" />
              <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                  (click)="viewPrivacyPolicy()">"Privacy
                  Policy"</a> and
                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                  Conditions"</a></span>
            </div>
          </div>
        </form>
      </div>
      <div style="text-align: center;">
        <button
          [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
          (click)="submitContactForm()"
          [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
          <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
        </button>
      </div>
    </div>
  </div> -->
</div>
<app-footer></app-footer>