<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Centralized Business Intelligence for Hotel Chains
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Gain 360° Visibility and Actionable Insights Across All Properties</h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Centralized Business Intelligence.png" />
            </div>
            <div class="guest-app-child-text">GoFriday's business intelligence solution streamlines multi-property
                management with real-time dashboards and analytics, empowering teams to boost performance and guest
                satisfaction.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Centralized Business Intelligence.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Key Features of Centralized Business Intelligence
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/BI Dashboard.png" alt="" />
                    <h3 class="why-choose-content-head">
                        BI Dashboard and Reporting for Chains
                    </h3>
                    <div class="why-choose-text">Consolidate data from all properties in one dashboard to track KPIs,
                        revenue, and guest satisfaction.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Dashboard Reporting.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Stakeholder Dashboard
                    </h3>
                    <div class="why-choose-text">Tailor dashboards for executives to focus on financial performance,
                        efficiency, and key KPIs.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Real-Time Feedback.png" alt="" />
                    <h3 class="why-choose-content-head">
                        360-Degree Visibility
                    </h3>
                    <div class="why-choose-text">Get a full view of operations, guest behavior, and staff performance
                        for smooth management and timely interventions.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits of GoFriday Centralized Business Intelligence
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Simplified Multi-Property Management</h3>
                <p>Manage all properties from a single platform with unified data and insights.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Data-Driven Decisions</h3>
                <p>Use real-time data to spot trends, optimize workflows, and enhance performance.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Actionable Insights for Stakeholders</h3>
                <p>Updates flow to staff, managers, and guests simultaneously for maximum transparency.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">04</div>
                <h3>Equip stakeholders with tailored dashboards for quick, informed decisions.</h3>
                <p>Leverage guest feedback and preferences to provide consistent, exceptional service.</p>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <h2 class="guest-app-child-heading2" style="padding: 0% 5% 5% 16%;" *ngIf="!mobile">
                Why Choose GoFriday for Centralized BI?
            </h2>
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Centralized BI.png">
                    </div>
                    <div fxLayout="column" fxFlex="44%" fxLayoutGap="2%">
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Centralized Data Access
                            </div>
                            <div class="features-sub-text">
                                All property data in one place for easier management.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Customizable Dashboards
                            </div>
                            <div class="features-sub-text">
                                Tailor dashboards for stakeholders and teams.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Real-Time Insights
                            </div>
                            <div class="features-sub-text">
                                Stay updated with live reporting and performance tracking.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="1%">
                            <div class="features-sub-heading">
                                Seamless Integration
                            </div>
                            <div class="features-sub-text">
                                Works with existing hotel systems like PMS and task apps.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 4% 5% 3% 5%;">
                        <h2 class="features-heading">
                            Why Choose GoFriday for Centralized BI?
                        </h2>
                    </div>
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/Centralized BI.png">
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 15% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Centralized Data Access
                        </div>
                        <div class="features-sub-text">
                            All property data in one place for easier management.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Customizable Dashboards
                        </div>
                        <div class="features-sub-text">
                            Tailor dashboards for stakeholders and teams.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Real-Time Insights
                        </div>
                        <div class="features-sub-text">
                            Stay updated with live reporting and performance tracking.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="2%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Seamless Integration
                        </div>
                        <div class="features-sub-text">
                            Works with existing hotel systems like PMS and task apps.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="45%">
            <img class="bottom-image" src="assets/images/Chain Operations.png">
        </div>
        <div fxFlex="45%" fxLayout="column" fxLayoutGap="1%">
            <div>
                <h2 class="header">Transform Hotel Chain <br> Operations with GoFriday</h2>
            </div>
            <div class="info-text">
                Simplify data management, empower decision-making, and drive operational excellence with GoFriday's centralized BI solution.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Transform Hotel Chain Operations with GoFriday</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Chain Operations.png">
            </div>
            <div class="info-text">
                Simplify data management, empower decision-making, and drive operational excellence with GoFriday's centralized BI solution.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>