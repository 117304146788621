import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-centralized-guest-profile-management',
  templateUrl: './centralized-guest-profile-management.component.html',
  styleUrls: ['./centralized-guest-profile-management.component.css']
})
export class CentralizedGuestProfileManagementComponent {
  mobile: boolean = false;
    public calendlyUrl = environment.CALENDLY_URL;
  
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.checkScreenWidth();
    }
  
    constructor(private router: Router) { }
    ngOnInit() {
      this.checkScreenWidth();
    }
  
    private checkScreenWidth() {
      if (window.innerWidth <= 1250) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  
    openCalendlyPopup() {
      Calendly.initPopupWidget({ url: this.calendlyUrl });
      return false;
    }
  
    selectTrial() {
      this.router.navigateByUrl('free-trial');
      window.scrollTo(0, 0);
    }

}
