<app-header></app-header>
<div class="plans-main-container" fxLayout="column">
    <div class="plans-container1" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="1%">
        <span class="plans-title">Plans That Fit Every Hotel’s Needs</span>
        <div class="plans-desc">
            At GoFriday, we make it simple for hotels of all sizes to enhance guest experiences and streamline
            operations. Explore our plans and start transforming the way you manage your property today!
        </div>
    </div>
    <!-- <div class="plans-container2" fxLayout="row" fxLayoutAlign="space-between" *ngIf="!mobile">
        <div fxLayout="column">
            <span class="trial-title">
                Your First 30 Days, Absolutely Free!
            </span>
            <button class="book-btn" (click)="openCalendlyPopup()">
                Book A Demo Today
            </button>
        </div>
        <div class="trial-image">
            <img class="trial-img" src="../../../assets/images/plans_trial.png" />
        </div>
    </div> -->
    <!-- <div class="plans-container2" fxLayout="column" *ngIf="mobile" fxLayoutAlign="start center">
        <div>
            <img class="trial-img" src="../../../assets/images/plans_trial.png" />
        </div>
        <span class="trial-title">
            Your First 30 Days, Absolutely Free!
        </span>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book A Demo Today
        </button>
    </div> -->
    <div id="Pricing" class="plans-container3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="13px"
        *ngIf="!mobile">
        <div class="box-container" fxLayout="row" fxLayoutGap="29px">
            <!-- <div style="align-self: center;" (click)="scrollLeft(container)">
                <mat-button class="scroll-btn">
                    <img src="assets/images/chevron-left.png">
                </mat-button>
            </div> -->
            <div class="plan-box-container" fxLayout="row" fxLayoutGap="29px">
                <div class="plan-box" *ngFor="let plan of plans" fxLayout="column" fxLayoutAlign="start center">
                    <div class="plan-header">
                        {{ plan.name }}
                    </div>

                    <div class="plan-details">
                        <div class="detail-row">
                            <span class="detail-header">Onboarding (One time):</span>
                            <span class="value">{{ plan.onboarding.fee }}</span>
                        </div>
                        <div class="detail-row">
                            <span class="detail-header">Subscription Per Month:</span>
                            <span class="value">{{ plan.subscription.fee }}</span>
                        </div>
                        <div class="detail-row">
                            <span class="detail-header">Best For:</span>
                            <span class="value">{{ plan.bestFor }}</span>
                        </div>
                    </div>

                    <button class="book-btn" (click)="openCalendlyPopup()"
                        style="align-items: center;justify-content: center;">
                        Book a Demo
                    </button>
                    <mat-divider></mat-divider>

                    <div class="features-section">
                        <div class="plan-name">Key Features:</div>
                        <div class="features-list">
                            <div class="feature-item" *ngFor="let feature of plan.features">
                                <span class="content">✓</span>
                                <span class="plan-features">{{ feature.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div style="align-self: center;">
                <mat-button class="scroll-btn" (click)="scrollRight(container)">
                    <img src="assets/images/chevron-right.png">
                </mat-button>
            </div> -->
        </div>
    </div>

    <div id="Pricing" class="plans-container3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="13px"
        *ngIf="mobile">
        <div class="box-container" fxLayout="row" fxLayoutGap="29px">
            <!-- <div style="align-self: center;" (click)="scrollLeft(container)">
                <mat-button class="scroll-btn">
                    <img src="assets/images/chevron-left.png">
                </mat-button>
            </div> -->
            <div class="plan-box-container" fxLayout="column" fxLayoutGap="29px">
                <div class="plan-box" *ngFor="let plan of plans" fxLayout="column" fxLayoutAlign="start center">
                    <div class="plan-header">
                        {{ plan.name }}
                    </div>

                    <div class="plan-details">
                        <div class="detail-row">
                            <span>Onboarding (One time):</span>
                            <span class="value">{{ plan.onboarding.fee }}</span>
                        </div>
                        <div class="detail-row">
                            <span>Subscription Per Month:</span>
                            <span class="value">{{ plan.subscription.fee }}</span>
                        </div>
                        <div class="detail-row">
                            <span>Best For:</span>
                            <span class="value">{{ plan.bestFor }}</span>
                        </div>
                    </div>

                    <button class="book-btn" (click)="openCalendlyPopup()"
                        style="align-items: center;justify-content: center;">
                        Book A Demo
                    </button>

                    <mat-divider></mat-divider>

                    <div class="features-section">
                        <div class="plan-name">Key Features:</div>
                        <div class="features-list">
                            <div class="feature-item" *ngFor="let feature of plan.features">
                                <span class="content">✓</span>
                                <span class="plan-features">{{ feature.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="plans-container2" fxLayout="column" fxLayoutAlign="start center">
        <span class="trial-title">
            Why GoFriday?
        </span>
        <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
            <div class="card-wrapper">
                <div class="background-image1"></div>
                <mat-card class="card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="card-image">
                            <img src="assets/images/plans-image1.png">
                        </div>
                        <div class="card-content">
                            <div class="card-header">
                                Hassle-Free Onboarding
                            </div>
                            <div class="card-text">
                                Start smoothly without upfront fees (small hotels).
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="card-wrapper">
                <div class="background-image2"></div>
                <mat-card class="card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="card-image">
                            <img src="assets/images/plans-image2.png">
                        </div>
                        <div class="card-content">
                            <div class="card-header">
                                Transparent Pricing
                            </div>
                            <div class="card-text">
                                Simple, Straightforward and no Hidden charges
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="card-wrapper">
                <div class="background-image3"></div>
                <mat-card class="card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="card-image">
                            <img src="assets/images/plans-image3.png">
                        </div>
                        <div class="card-content">
                            <div class="card-header">
                                Built to Scale
                            </div>
                            <div class="card-text">
                                Our tools grow with your property
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>



    <div id="Talk-to-Sales" class="plans-container4" fxLayout="column" fxLayoutAlign="start center" *ngIf="!mobile">
        <span class="quote-header">
            Start Today, Transform Tomorrow
        </span>
        <span class="quote">
            No matter your hotel’s size, GoFriday is here to support your journey to better operations and happier
            guests.
        </span>
        <button class="book-btn" (click)="selectFreeTrial()">
            Get Started for Free
        </button>
    </div>
    <div id="Talk-to-Sales" class="plans-container4" fxLayoutAlign="start center" fxLayout="column" *ngIf="mobile">
        <span class="quote-header">
            Start Today, Transform Tomorrow
        </span>
        <span class="quote">
            No matter your hotel’s size, GoFriday is here to support your journey to better operations and happier
            guests.
        </span>
        <button class="book-btn" (click)="selectFreeTrial()">
            Get Started for Free
        </button>
    </div>
    <!-- <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
        <div class="contact-image" fxLayout="column" fxFlex="50%">
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div>
                <img src="assets/images/contact-us.png">
            </div>
        </div>
        <div class="contact-form" fxLayout="column" fxFlex="50%">
            <div class="form-heading">
                Contact Us
            </div>
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                placeholder="Email" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div> -->
    <!-- <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
        <div class="form-heading">
            Contact Us
        </div>
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div style="text-align: center;">
            <img src="assets/images/contact-us.png">
        </div>
        <div class="contact-form" fxLayout="column">
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div> -->
</div>
<app-footer></app-footer>