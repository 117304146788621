import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../../popup/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog17',
  templateUrl: './blog17.component.html',
  styleUrls: ['./blog17.component.css']
})
export class Blog17Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'What is a hotel management system?',
      answer: 'A hotel management system is a software platform designed to streamline hotel operations, including room reservations, guest check-ins and check-outs, housekeeping, billing, and reporting. It enhances efficiency and helps hotel staff focus on delivering better guest experiences.',
      open: false
    },
    {
      question: 'Can a Hotel Room Management System integrate with other hotel software?',
      answer: 'Yes, most Hotel Room Management Systems can integrate with other hotel software, such as Property Management Systems (PMS), booking engines, and customer relationship management (CRM) platforms. This integration enables seamless data sharing, improving efficiency and enhancing the guest experience.',
      open: false
    },
    {
      question: 'What are the challenges of using a Hotel Room Management System?',
      answer: `Challenges include:
      <ol>
        <li><strong>Initial Cost:</strong> Upfront investment and implementation time can be high, especially for smaller hotels.</li>
        <li><strong>Training:</strong> Staff may need time to learn the new system, impacting short-term productivity.</li>
        <li><strong>Technical Issues:</strong> Occasional downtime or technical glitches can disrupt operations.</li>
        <li><strong>Data Security:</strong> Protecting guest data is crucial, requiring compliance with data protection regulations.</li>
      </ol>
    `,
      open: false
    },
    {
      question: 'What future trends are expected in Hotel Room Management Systems?',
      answer: `Future trends include:
      <ol>
        <li><strong>AI Integration:</strong> AI and machine learning will enhance personalization and optimize pricing.</li>
        <li><strong>IoT Connectivity:</strong> Increased integration with smart devices for automated controls and services.</li>
        <li><strong>Mobile Functionality:</strong> Mobile Functionality.</li>
        <li><strong>Sustainability Features:</strong> Tools for energy management to reduce environmental impact.</li>
        <li><strong>Cloud Solutions:</strong> Growth of cloud-based systems for scalability and reduced costs.</li>
      </ol>
    `,
      open: false
    }
  ];

  articles = [
    {
      title: 'What Does Travel Sustainable Mean? Key Concepts Explained',
      text: 'Travel is a means by which individuals open themselves to new cultures...',
      image: 'assets/images/Travel Sustainable-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewTravelSustainable()
    },
    {
      title: 'THE GUEST-CENTRIC REVOLUTION',
      text: 'Artificial Intelligence (AI) has transitioned from a futuristic concept to....',
      image: 'assets/images/guest-image.png',
      readTime: '3 Minutes Read',
      onClick: () => this.viewRevolution()
    },
    {
      title: 'AI in Hospitality Industry: Transforming Guest Experiences',
      text: 'Imagine you go to the hotel, the room temperature is just as you like it...',
      image: 'assets/images/AI-in-Hospitality Industry-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewAIInHospitalityIndustry()
    }
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Hotel-Room-Management-System',
      'Features-of-Hotel-Room',
      'Hotel-Room-Efficiency',
      'Benefits-of-Hotel-Room-Management',
      'Choosing-Right-Hotel',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewAIInHospitalityIndustry() {
    this.router.navigateByUrl('blog/ai-in-hospitality-industry');
    window.scrollTo(0, 0);
  }

  viewRevolution() {
    this.router.navigateByUrl('blog/guest-centric-revolution');
    window.scrollTo(0, 0);
  }

  viewTravelSustainable() {
    this.router.navigateByUrl('blog/what-does-travel-sustainable-mean');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/hotel-room-management-system`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewHotelRoomManagementSystem() {
    this.scrollToSection('Hotel-Room-Management-System');
  }

  viewFeaturesOfHotelRoom() {
    this.scrollToSection('Features-of-Hotel-Room');
  }

  viewHotelRoomEfficiency() {
    this.scrollToSection('Hotel-Room-Efficiency');
  }

  viewHotelRoomManagementBenefits() {
    this.scrollToSection('Benefits-of-Hotel-Room-Management');
  }

  viewChoosingRightHotel() {
    this.scrollToSection('Choosing-Right-Hotel');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
